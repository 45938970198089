<template>
  <div class="emblem-wrapper">
    <div :class="getBadgeClass(achiev)" :style="style" aria-label="Badge">
      <img v-if="!lessThan(tiers, 2)" :src="src" alt="Badge Stars" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getImgSrc as $img } from '@/utils/helperFunctions'
import type { Achievement, AchievementImage } from './types'
import { computed } from 'vue'

type Props = { achiev: Achievement | AchievementImage; size?: number }
const props = defineProps<Props>()

const numStars = computed(() => props.achiev.currentTier)
const src = computed(() => $img(`images/achievements/stars_${numStars.value}.png`))

const lessThan = (length: number, max: number) => length < max
const tiers = computed(() => {
  if (!props.achiev.tiers) return 0
  if (typeof props.achiev.tiers === 'number') {
    return props.achiev.tiers
  }
  return props.achiev.tiers.length
})

function getBadgeClass(achiev: Achievement | AchievementImage) {
  const arr = ['badge']
  if (achiev.currentTier === 0) arr.push('gray-badge')
  return arr
}

const style = computed(() => ({
  minHeight: props.size ? props.size + 'px' : '140px',
  maxHeight: props.size ? props.size + 'px' : '140px',
  minWidth: props.size ? props.size + 'px' : '140px',
  maxWidth: props.size ? props.size + 'px' : '140px',
  backgroundImage: `url(${props.achiev.badgeImg})`,
}))
</script>

<style lang="scss" scoped>
.emblem-wrapper {
  .badge {
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;

    img {
      margin-top: 72.86%;
      width: 64.29%;
      height: 22.86%;
    }
  }

  .gray-badge {
    filter: saturate(0);
  }
}
</style>
