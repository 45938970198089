<template>
  <v-menu
    :close-on-content-click="false"
    :return-value.sync="value"
    max-width="290px"
    min-width="290px"
    offset-y
    ref="menu"
    transition="scale-transition"
    v-model="menu"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :append-icon="appendIcon"
        :label="label"
        :value="
          internalHint
            ? internalHint
            : inPast
            ? 'Date cannot be in the future'
            : value
        "
        @input="$emit('input', $event)"
        clearable
        :outlined="outlined"
        v-on="on"
        :hint="hint"
        autocomplete="off"
      />
    </template>
    <v-date-picker
      :value="dateValue"
      @change="(v) => $refs.menu.save(v)"
      @input="$emit('input', $event)"
      no-title
      scrollable
    >
      <!-- <div>
        <v-spacer/>
        <v-btn @click="menu = false" color="primary" text>Cancel</v-btn>
        <v-btn @click="$refs.menu.save(value)" color="primary" text>OK</v-btn>
      </div> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DateField',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: '',
    },
    outlined: { type: Boolean, default: false },
    appendIcon: { type: String, default: '' },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    dateValue() {
      // check if the stored date string is valid
      //
      // this allows the text field to display the stored value without passing
      // an invalid date to the date picker.
      if (isNaN(Date.parse(this.value))) return ''

      return this.value
    },
    inPast() {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10)
      console.log('current date is: ', currentDateWithFormat)

      if (this.value > currentDateWithFormat) return true
      return false
    },
    internalHint() {
      if (this.value == 0) return 'A date must be chosen'
    },
  },
}
</script>
