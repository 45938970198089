<template>
  <v-card class="streak-wrapper" :class="lite ? 'lite' : ''" elevation="0">
    <img
      v-if="isCompletedStreak"
      :src="getImgSrc('right_confetti.png')"
      class="confetti left"
    />
    <img
      v-if="isCompletedStreak"
      :src="getImgSrc('left_confetti.png')"
      class="confetti right"
    />
    <div class="d-flex mb-2 streak-txt" style="font-weight: 700">
      <span>Challenge Streak</span>
      <v-spacer />
      <span>{{ computedStreak.streak }}/{{ computedStreak.streakGoal }}</span>
    </div>
    <div class="green-box-wrapper mb-4" style="font-weight: 700">
      <div v-for="num in nums" :key="num" :class="getClass(num)">{{ num }}</div>
    </div>
    <div v-if="!lite && !isCompletedStreak">
      Complete the challenge 5 times in a row for an extra reward of
      <span style="font-weight: 700">
        <img src="/src/assets/images/goldCoin.svg" style="max-height: 12px" />
        {{ computedStreak.currencyReward }}
        <img src="/src/assets/images/experience.svg" style="max-height: 12px" />
        {{ computedStreak.xpReward }}</span
      >
    </div>
    <div v-else-if="isCompletedStreak">
      Challenge streak completed! Your reward is
      <span style="font-weight: 700">
        <img src="/src/assets/images/goldCoin.svg" style="max-height: 12px" />
        {{ computedStreak.currencyReward }} and
        <img src="/src/assets/images/experience.svg" style="max-height: 12px" />
        {{ computedStreak.xpReward }}</span
      >!
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { useChallengeStore } from '@/stores/challengeStore'
import { getImgSrc } from '@/utils/helperFunctions'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps<{
  /**True if the component should display in lite version (with less info). */
  lite?: boolean
  /**True if the component should use challengeStore's answerStreak instead of streak. */
  isReward?: boolean
}>()
const store = useChallengeStore()
const { streak, answerStreak } = storeToRefs(store)

/**Uses the answer streak if prop is set, else the normal streak. */
const computedStreak = computed(() => {
  if (props.isReward) return answerStreak.value
  return streak.value
})
const isCompletedStreak = computed(
  () =>
    props.isReward && computedStreak.value.streak === computedStreak.value.streakGoal
)

const nums = computed(() => {
  const toReturn = []
  let counter = computedStreak.value.streakGoal
  if (!counter) counter = 5
  do {
    toReturn.unshift(counter)
    counter--
  } while (counter > computedStreak.value.streakGoal - 5)
  return toReturn
})

function getClass(num: number) {
  if (computedStreak.value.streak >= num) return 'box green-box'
  return 'box gray-box'
}
</script>

<style lang="scss" scoped>
.streak-wrapper {
  padding: 20px;
  border-radius: 12px;
  background: #f4f9ff;
  font-size: 14px;
  font-weight: 500;
  position: relative;

  .confetti {
    position: absolute;
  }
  .confetti.left {
    left: -90px;
  }
  .confetti.right {
    right: -90px;
  }

  .green-box-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .box {
    /* width: 55px; */
    width: 18%;
    height: 24px;
    /* flex-shrink: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .green-box {
    color: white;

    background-color: var(--additional-green, #77c46a);
  }

  .gray-box {
    color: black;
    background-color: #e4f3e1;
  }
}

.streak-wrapper.lite {
  padding: 0;
  background: unset;
}
</style>
