<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M19.8067 4.19334L15.8067 0.193344C15.7444 0.131557 15.6705 0.0826733 15.5893 0.049497C15.508 0.0163206 15.4211 -0.000496119 15.3333 1.11429e-05H2C1.46957 1.11429e-05 0.960859 0.210725 0.585786 0.585797C0.210714 0.96087 0 1.46958 0 2.00001V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V4.66668C20.0005 4.57894 19.9837 4.49196 19.9505 4.41074C19.9173 4.32951 19.8684 4.25564 19.8067 4.19334ZM12.6667 1.33334V5.33334H7.33333V1.33334H12.6667ZM4.66666 18.6667V14C4.66666 13.8232 4.7369 13.6536 4.86193 13.5286C4.98695 13.4036 5.15652 13.3333 5.33333 13.3333H14.6667C14.8435 13.3333 15.013 13.4036 15.1381 13.5286C15.2631 13.6536 15.3333 13.8232 15.3333 14V18.6667H4.66666ZM18.6667 18C18.6667 18.1768 18.5964 18.3464 18.4714 18.4714C18.3464 18.5964 18.1768 18.6667 18 18.6667H16.6667V14C16.6667 13.4696 16.4559 12.9609 16.0809 12.5858C15.7058 12.2107 15.1971 12 14.6667 12H5.33333C4.8029 12 4.29419 12.2107 3.91912 12.5858C3.54404 12.9609 3.33333 13.4696 3.33333 14V18.6667H2C1.82319 18.6667 1.65362 18.5964 1.52859 18.4714C1.40357 18.3464 1.33333 18.1768 1.33333 18V2.00001C1.33333 1.8232 1.40357 1.65363 1.52859 1.52861C1.65362 1.40358 1.82319 1.33334 2 1.33334H6V5.33334C6 5.68696 6.14047 6.0261 6.39052 6.27615C6.64057 6.5262 6.97971 6.66667 7.33333 6.66667H12.6667C13.0203 6.66667 13.3594 6.5262 13.6095 6.27615C13.8595 6.0261 14 5.68696 14 5.33334V1.33334H15.06L18.6667 4.94001V18Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusSave',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
