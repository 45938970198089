<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C9.62663 0 7.30655 0.703787 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519945 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9962 8.81855 22.7308 5.76849 20.4811 3.51886C18.2315 1.26924 15.1814 0.00375324 12 0ZM12 21.8182C10.0582 21.8182 8.15991 21.2424 6.54531 20.1635C4.93072 19.0847 3.6723 17.5513 2.92919 15.7573C2.18607 13.9632 1.99164 11.9891 2.37048 10.0846C2.74931 8.18003 3.6844 6.43059 5.0575 5.0575C6.4306 3.6844 8.18003 2.74931 10.0846 2.37047C11.9891 1.99163 13.9632 2.18607 15.7573 2.92918C17.5513 3.6723 19.0847 4.93072 20.1635 6.54531C21.2424 8.1599 21.8182 10.0581 21.8182 12C21.8153 14.6031 20.78 17.0987 18.9393 18.9393C17.0987 20.78 14.6031 21.8153 12 21.8182Z"
      fill="url(#paint0_linear_1566_30591)"
    />
    <path
      d="M16.7173 8.46863L10.942 13.7628L8.40781 11.2286C8.30718 11.1244 8.1868 11.0413 8.05371 10.9842C7.92061 10.927 7.77747 10.8969 7.63261 10.8956C7.48776 10.8944 7.34411 10.922 7.21005 10.9768C7.07598 11.0317 6.95417 11.1127 6.85175 11.2151C6.74932 11.3175 6.66831 11.4393 6.61346 11.5734C6.55861 11.7075 6.53101 11.8511 6.53227 11.996C6.53353 12.1408 6.56362 12.284 6.62079 12.4171C6.67797 12.5502 6.76108 12.6705 6.86527 12.7712L10.138 16.0439C10.3368 16.2427 10.6047 16.3571 10.8858 16.3632C11.1669 16.3693 11.4395 16.2667 11.6467 16.0766L18.1922 10.0766C18.4054 9.88104 18.5322 9.60876 18.5447 9.31968C18.5572 9.03061 18.4543 8.74841 18.2587 8.53518C18.0631 8.32194 17.7909 8.19514 17.5018 8.18266C17.2127 8.17018 16.9305 8.27304 16.7173 8.46863Z"
      fill="url(#paint1_linear_1566_30591)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1566_30591"
        x1="19.2275"
        y1="1.26195"
        x2="-11.536"
        y2="36.9488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7DD06F" />
        <stop offset="1" stop-color="#65A25B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1566_30591"
        x1="16.1568"
        y1="8.61185"
        x2="6.8978"
        y2="24.3827"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7DD06F" />
        <stop offset="1" stop-color="#65A25B" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'cusGreenCheck',
}
</script>

<style></style>
