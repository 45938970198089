<template>
  <v-dialog v-model="birthdayModal.show" persistent max-width="400px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-if="showDateField">
        <p>
          We seem to be missing your birthday in our records. Can you please let us
          know?
        </p>
        <DateField
          v-model="studentbirthday"
          outlined
          label="Date of Birth"
          hint=""
        />
      </v-card-text>
      <v-card-text v-else> Thank you for your help! </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          color="primary"
          style="color: black"
          :loading="isLoading"
          >Submit</v-btn
        >
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from '@/components/DateField.vue'
import { useModalStore } from '@/stores/modalStore'
import userData from '@/utils/ajax/userData'
import { mapWritableState } from 'pinia'
export default {
  name: 'BirthdayModal',
  components: { DateField },
  data() {
    return {
      showDateField: true,
      isLoading: false,
      studentbirthday: null,
      title: 'Sorry to bother you, but...',
    }
  },
  computed: { ...mapWritableState(useModalStore, ['birthdayModal']) },
  methods: {
    submit() {
      this.isLoading = true
      userData
        .postStudentBirthday(this.studentbirthday)
        .then(() => {
          this.title = 'Thank you'
          this.showDateField = false
          setTimeout(() => {
            this.birthdayModal.show = false
            this.isLoading = false
          }, 1500)
        })
        .catch(() => (this.isLoading = false))
    },
  },
}
</script>
