<template>
  <FeedNotification v-if="notification.feed" :notification="notification" />
  <v-list-item
    v-else
    :class="notification.readStatus === 1 ? '' : 'unread-notification'"
    class="d-block notification px-0"
  >
    <br />
    <NotificationTop :notification="notification" class="px-4" />
    <v-list-item-content
      :class="['d-block px-4 message', $vuetify.theme.dark ? 'white--text' : '']"
      v-html="notification.message"
    >
    </v-list-item-content>
    <v-list-item-content class="survey-link-wrapper">
      <div class="d-block px-4">
        <v-btn
          v-if="notification.noticeBoxType === 4 && !notification.dismissed"
          @click="router.push('/feedback')"
          outlined
          small
          >{{ notification.buttonText }}
        </v-btn>
        <v-btn
          v-else-if="notification.buttonLink"
          title="Go to page"
          small
          outlined
          @click="$router.push(notification.buttonLink)"
          >{{ notification.buttonText }}
        </v-btn>
        <v-btn
          v-else-if="notification.buttonText !== null"
          title="Go to page"
          small
          outlined
          @click="$emit('go-to-notification', notification.id)"
          >{{ notification.buttonText }}
        </v-btn>
        <br />
        <br />
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script setup lang="ts">
import FeedNotification from './FeedNotification.vue'
import NotificationTop from './NotificationTop.vue'
import { Notification } from '@/stores/notificationsStore'

import dayjs from '@/utils/dayjs'
import utc from 'dayjs/plugin/utc'
import { useRouter } from 'vue-router/composables'
dayjs.extend(utc)

type Props = { notification: Notification }
const props = defineProps<Props>()

type Emits = { (e: 'go-to-notification', id: number): void }
const emit = defineEmits<Emits>()
const router = useRouter()
</script>

<style lang="scss" scoped>
.notification {
  font-size: 14px;
}

.notification-feedback-link {
  color: blue;
}

.survey-link-wrapper {
  padding: 2px 0;
}
.unread-notification {
  .message {
    color: black;
  }
}
</style>
