<template>
  <div class="underline-btn">
    <v-icon v-if="icon" @click="clicked" :small="small" class="mr-1">{{
      icon
    }}</v-icon>
    <a @click="clicked" class="underline-a"><slot /></a>
  </div>
</template>

<script>
export default {
  name: 'UnderlineButton',
  props: {
    url: { default: null, type: String },
    small: { default: false, type: Boolean },
    icon: { type: String, default: null },
  },
  methods: {
    clicked() {
      if (this.url) this.$router.push(this.url)
      else this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.underline-a {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: var(--v-secondary-base);
}
</style>
