import { computed, ref } from 'vue'
import client from '../ajax/http'
import { useAccountStore } from '@/stores/accountStore'

export default function useCancelledSessions() {
  const isLoading = ref({ fetch: false })
  const cancelledSessionsToday = ref([] as CancelledSession[])
  const sessionsToShow = ref([] as CancelledSession[])
  const showModal = computed(() => sessionsToShow.value.length > 0)
  const THIRTY_SECONDS_IN_MILLISECONDS = 1000 * 30
  const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24

  async function beginChecking() {
    const store = useAccountStore()
    if (!store.account?.id) return
    if (store.account.status !== 'ACTIVE') {
      setTimeout(beginChecking, ONE_DAY_IN_MILLISECONDS)
    } else {
      await fetchCancelledSessions()
      setTimeout(beginChecking, THIRTY_SECONDS_IN_MILLISECONDS)
    }
  }

  function fetchCancelledSessions() {
    isLoading.value.fetch = true
    return client
      .get<CancelledSessionRes>('/dashboard/cancelled-sessions')
      .then((res) => {
        cancelledSessionsToday.value = res.data.sessions

        const toShow = []
        for (const ses of res.data.sessions) {
          if (!checkIfAlreadyDisplayed(ses.id)) {
            toShow.push(ses)
          }
        }
        sessionsToShow.value = toShow
      })
      .finally(() => (isLoading.value.fetch = false))
  }

  function addToLocalStorage(sessonId: number) {
    localStorage.setItem(`session_cancelled:${sessonId}`, 'CANCELLED')
  }

  function checkIfAlreadyDisplayed(sessionId: number) {
    const val = localStorage.getItem(`session_cancelled:${sessionId}`)
    return val && val === 'CANCELLED'
  }

  function clearSessions() {
    for (const ses of sessionsToShow.value) {
      addToLocalStorage(ses.id)
    }
    sessionsToShow.value = []
  }

  return {
    beginChecking,
    fetchCancelledSessions,
    sessionsToShow,
    showModal,
    clearSessions,
  }
}

type CancelledSession = {
  id: number
  description: string
  startingAt: string
  teacherName: string
}

type CancelledSessionRes = {
  sessions: CancelledSession[]
}
