<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5358 3.09574H11.2057C10.9487 3.09574 10.6978 2.98977 10.5244 2.80964L8.54933 0.719843C8.07456 0.244514 7.42499 -0.00376266 6.7671 4.31033e-05H2.46422C1.10551 3.86859e-06 0 1.11038 0 2.47505V14.5249C0 15.8896 1.10551 17 2.46422 17H17.5448H17.5471C18.9051 16.994 20.006 15.8783 20 14.5173V5.57078C20 4.20608 18.8945 3.09574 17.5358 3.09574ZM18.4928 14.5204C18.4951 15.0502 18.0678 15.4839 17.5433 15.4862H2.46422C1.93672 15.4862 1.50719 15.0548 1.50719 14.525V2.47505C1.50719 1.94522 1.93672 1.5138 2.46422 1.51301H6.78671C6.79499 1.51301 6.80327 1.51301 6.81159 1.51301C7.06327 1.51301 7.30745 1.61369 7.47171 1.77718L9.43628 3.85713C9.89745 4.33548 10.5425 4.60949 11.2057 4.60949H17.5358C18.0633 4.60949 18.4928 5.04092 18.4928 5.57074V14.5204H18.4928Z"
      fill="#3D4A65"
    />
  </svg>
</template>

<script>
export default { name: 'cusNewFolder' }
</script>

<style></style>
