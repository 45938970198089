<template>
  <div class="notices-wrapper">
    <div
      style="background: #fff5dc"
      v-if="store.userRolesIncludesOneOf(['full', 'impersonate']) && state.show"
      class="py-4 impersonate-alert"
    >
      <v-form
        class="d-flex"
        style="align-items: center; justify-content: center"
        @submit.prevent="impersonateUser"
      >
        <span> You're an admin. Who do you want to impersonate?</span>
        <TextField
          v-model="state.userToImpersonate"
          dense
          label="Username"
          outlined
          hide-details
          style="display: inline; max-width: 400px"
          class="mx-5"
        />
        <PrimaryButton type="submit">Go</PrimaryButton>
        <v-btn @click="state.show = false" class="ml-3" color="red" elevation="0">
          Hide
        </v-btn>
      </v-form>
    </div>
    <div
      v-if="store.impersonate && !store.account.admin && state.show"
      type="warning"
      style="text-align: center; background: #fff5dc"
      class="py-3 impersonate-alert"
    >
      <span
        >You are impersonating <strong>{{ store.account.username }}</strong
        >. Don't forget to logout when you're finished.</span
      >
      <PrimaryButton @click="store.endImpersonate()" class="ml-4"
        >Stop</PrimaryButton
      >
      <v-btn @click="state.show = false" class="ml-3" color="red" elevation="0">
        Hide
      </v-btn>
    </div>
    <section class="notices">
      <img class="arrows-right" src="/src/components/customIcons/arrowsRight.svg" />
      <img class="arrows-down" src="/src/components/customIcons/arrowsDown.svg" />
      <template v-if="!store.hideNavigation">
        <div v-for="notice in state.notices" :key="notice.id" class="notice">
          <v-icon class="icon">$vuetify.icons.cusMegaphone</v-icon>
          <v-btn
            v-if="!notice.actionUrl"
            class="icon-right"
            icon
            small
            @click="dismiss(notice.id)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <PrimaryButton
            v-else
            class="icon-right"
            @click="onNoticeClick(notice)"
            small
          >
            {{ notice.actionLabel }}
          </PrimaryButton>
          <div class="text-wrapper" v-html="notice.message" />
        </div>
      </template>
    </section>
  </div>
</template>

<script setup lang="ts">
// These are the notices shown below the nav bar --- not the ones in the bell icon
import { useAccountStore } from '@/stores/accountStore'
import { reactive, onBeforeUnmount, ref } from 'vue'
import { app } from '@/main'
import { mixpanel } from '@/utils/helperFunctions'

type Notice = {
  id: number
  message: string
  location?: string
  actionUrl?: string
  actionLabel?: string
}

const state = reactive({
  show: true,
  userToImpersonate: null as string,
  notices: [] as Notice[],
  t: null as number,
  text: ``,
})
const isLoading = ref({ dismiss: false })

get()
state.t = setInterval(get, 3600 * 1000)

const store = useAccountStore()

onBeforeUnmount(() => {
  if (state.t !== null) clearInterval(state.t)
})

function get() {
  app.$http
    .get<Notice[]>('/notices/', { params: { location: 'LEARN' } })
    .then(({ data }) => {
      for (const notice of data) {
        if (notice.id) mixpanel('Nudge displayed', { id: notice.id })
      }
      state.notices = data
    })
}

/**Sets the user_id and notice_id in the user_announcments table, dismissing the notice/announcment */
function dismiss(id: number) {
  isLoading.value.dismiss = true
  let index = state.notices
    .map((x) => {
      return x.id
    })
    .indexOf(id)

  app.$http
    .delete(`/notices/dismiss/${id}`)
    .then(() => state.notices.splice(index, 1))
    .finally(() => (isLoading.value.dismiss = false))
}

async function onNoticeClick(notice: Notice) {
  await mixpanel('Nudge action clicked', { id: notice.id })
  window.open(notice.actionUrl, '_blank')
}

function impersonateUser() {
  store.beginImpersonate(state.userToImpersonate)
}
</script>

<style lang="scss" scoped>
.notices {
  background: #0c1d3f;
  min-height: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 28px;
  position: relative;

  .arrows-right {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .arrows-down {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .notice {
    width: 70%;
    max-width: 1200px;
    padding: 15px 25px;
    margin: 6px 0;
    padding-bottom: 5px;
    background: #ffffff;
    border: 2px solid #ffde4b;
    border-radius: 20px !important;
    position: relative;
    padding-bottom: 10px;

    .icon {
      position: absolute;
      left: 20px;
      top: 10px;
    }

    .icon-right {
      position: absolute;
      right: 20px;
      top: 10px;
    }

    .text-wrapper {
      word-wrap: break-word;
      font-size: 14px;
      text-align: center;
      margin: 0 20px;
      margin-top: -3px;
      color: black;

      a {
        color: black !important;
      }
    }
  }
}
</style>
