<template>
  <div style="display: flex; align-items: center">
    <!-- HIGH LVL DROPDOWN -->
    <v-select
      v-if="currentClasses.length > 1"
      v-model="selectedClassId"
      :items="currentClasses"
      item-text="name"
      item-value="id"
      label="Class"
      style="min-width: 200px; max-width: 220px"
      dense
    />
    <v-divider v-if="currentClasses.length > 1" class="mx-4" vertical />

    <NavChallenge v-if="!disableChallenges" hide-sec />
    <v-divider v-if="!disableChallenges" class="mx-4" vertical />

    <!-- REFERRAL BUTTON -->
    <img
      :src="$img('images/referral/nav-icon.png')"
      alt="referral-link"
      style="max-width: 42px; cursor: pointer"
      @click="navToReferral"
    />
    <v-divider class="mx-4" vertical />

    <!-- OFFICE HOURS BTN -->
    <v-btn
      v-if="officeHoursAppointment"
      :elevation="0"
      class="primary-btn hidden-xs-only mr-2"
      @click.prevent="goToLink(officeHoursAppointment)"
    >
      <v-icon left small>mdi-briefcase-outline</v-icon>
      <span>Join Office Hours</span>
    </v-btn>

    <!-- JOIN CLASS BTN -->
    <JoinClassBtn :is-loading="isLoading" @go-to-meeting="$emit('go-to-meeting')" />

    <!-- NOTIFICATION BELL -->
    <NotificationBell />

    <!-- AVATAR -->
    <v-menu :close-on-content-click="false" offset-y class="av-menu">
      <template v-slot:activator="{ on }">
        <v-btn class="hidden-xs-only" icon v-on="on">
          <v-avatar color="#f3f3f3">
            <v-img :src="account.avatar" lazy-src="vuetify-loader" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list style="min-width: 220px">
        <v-list-item @click="openParentPortal" class="text-decoration-none">
          <v-list-item-title
            class="color--text text--darken-3 font-weight-moderate"
            style="display: flex; align-items: center"
          >
            <span>Parent Portal</span> <v-icon class="ml-2">mdi-open-in-new</v-icon>
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-0" />

        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="darkTheme" @change="switchDarkTheme" />
          </v-list-item-action>
          <v-list-item-title class="color--text text--darken-3 font-weight-moderate">
            Dark Theme
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-0" />

        <v-list-item @click="sendLogout">
          <v-list-item-title class="color--text text--darken-3 font-weight-moderate">
            Log Out
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-btn elevation="0" class="referral-card" @click="profileToReferral">
        <img :src="$img('images/referral/nav-icon.png')" style="max-width: 34px" />
        <span class="ml-2" style="font-weight: 700">Referral Rewards</span>
      </v-btn>
    </v-menu>
  </div>
</template>

<script>
import NotificationBell from '@/components/Notifications/NotificationBell.vue'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAccountStore } from '@/stores/accountStore'
import { useChallengeStore } from '@/stores/challengeStore'

export default {
  name: 'NavButtons',
  props: { isLoading: { type: Object, required: true } },
  data() {
    return {
      officeHoursAppointment: 0,
      darkTheme: this.$vuetify.theme.dark,
      isLoading: { clearAllBtn: false },
      numOfUnread: 0,
    }
  },
  computed: {
    ...mapState(useAccountStore, ['account', 'currentClasses']),
    ...mapWritableState(useAccountStore, ['selectedClassId']),
    ...mapState(useChallengeStore, ['disableChallenges']),
  },
  mounted() {
    this.checkAppointment()
  },
  methods: {
    ...mapActions(useAccountStore, ['logout']),
    goToLink(link) {
      if (link && link.startsWith('http')) {
        window.open(link, '_blank')
      }
    },
    navToReferral() {
      this.$router.push('/referral')
      this.$mixpanel('Click Referral Rewards', { location: 'Student Navigation' })
    },

    sendLogout() {
      this.$mixpanel('Click Log Out')
      this.logout()
    },
    switchDarkTheme() {
      localStorage.setItem('darkTheme', !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.darkTheme = this.$vuetify.theme.dark

      // hack to change bottom of page color if dark mode on
      const bodyNode = document.querySelector('body')
      if (this.darkTheme) {
        bodyNode.style.background = '#111315'
      } else {
        bodyNode.style.background = 'white'
      }
    },
    checkAppointment() {
      // check for a new appointment every 5 minutes.
      // once a appointment is due within the next 30 minutes,
      // check to see if it has been started every 60 seconds
      // in order to show the 'Join Office Hours' button.
      // Button will stay there for 30 minutes after the appointment time.
      this.$http.get('/dashboard/office-hours').then((res) => {
        this.officeHoursAppointment = res.data.url
        if (this.officeHoursAppointment) {
          setTimeout(this.checkAppointment, 60 * 1000)
        } else {
          setTimeout(this.checkAppointment, 300 * 1000)
        }
      })
    },

    openParentPortal() {
      this.$mixpanel('Click Parent Portal')
      window.open('https://parents.codewizardshq.com/', '_blank')
    },

    profileToReferral() {
      this.$router.push('/referral')
      this.$mixpanel('Click Referral Rewards', {
        location: 'Student Profile Dropdown',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-button-wrapper {
  margin: 0 10px;
}
</style>

<style>
.goldCoin {
  vertical-align: middle;
}

.xp {
  vertical-align: middle;
}

.referral-card {
  background-color: #edeeef;
  min-height: 56px;
  width: 86%;
  margin: 0 16px;
  margin-bottom: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
</style>
