import {
  ChallengeRes,
  RecentChallengesRes,
  ChallengeNavigationRes,
} from './../../stores/challengeStore'
import client from './http'

export default {
  async postChallengeTestingAnswer(
    challengeId: number,
    toSend: Record<string, any>
  ) {
    return client.post(`/challenges/testing/${challengeId}`, toSend)
  },
  async getChallengeForTesting(challengeId: number) {
    return client.get(`/challenges/testing/${challengeId}`)
  },
  async getChallengeQuestion(challengeId: number, classId: number) {
    const params = { challengeId, classId }
    return client.get<ChallengeRes>('/challenges/challenge', { params })
  },
  async getRecentChallengeQuestions(count = 8) {
    return client.get<RecentChallengesRes>(`/challenges/recent?count=${count}`)
  },
  async getChallengeGlobalInfo() {
    return client.get<ChallengeNavigationRes>('/challenges/')
  },
  async postChallengeAnswer(
    id: number,
    toSend: Record<string, any>,
    classId: number
  ) {
    return client.post(`/challenges/answer/${id}`, toSend, {
      params: { classId },
    })
  },
}
