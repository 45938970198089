<template>
  <v-text-field
    v-if="!textArea"
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="item"
    :menu-props="{ bottom: true, offsetY: true, rounded: 't-xl b-xl' }"
  />
  <v-textarea
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="item"
    :menu-props="{ bottom: true, offsetY: true, rounded: 't-xl b-xl' }"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'TextField',
  props: { textArea: { type: Boolean, default: false } },
}
</script>

<style lang="scss" scoped>
.item {
  border-radius: 8px;
}
</style>
