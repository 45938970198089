<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6667 6.33325V9.33325H0V6.33325C0 4.30664 1.63989 2.66675 3.66675 2.66675H23C25.0266 2.66675 26.6667 4.30664 26.6667 6.33325Z"
      fill="#457EFF"
    />
    <path
      d="M12.6667 23.3333C12.6667 17.4519 17.4519 12.6667 23.3333 12.6667C24.4988 12.6667 25.616 12.8613 26.6667 13.208V9.33325H0V21.6667C0 23.6934 1.63989 25.3333 3.66675 25.3333H12.8613C12.7373 24.6841 12.6667 24.0173 12.6667 23.3333Z"
      fill="#D8DBE0"
    />
    <path
      d="M6.66675 6.66675C5.93066 6.66675 5.33325 6.06934 5.33325 5.33325V1.33325C5.33325 0.597412 5.93066 0 6.66675 0C7.40259 0 8 0.597412 8 1.33325V5.33325C8 6.06934 7.40259 6.66675 6.66675 6.66675Z"
      fill="#3D4A65"
    />
    <path
      d="M20 6.66675C19.2639 6.66675 18.6667 6.06934 18.6667 5.33325V1.33325C18.6667 0.597412 19.2639 0 20 0C20.7361 0 21.3333 0.597412 21.3333 1.33325V5.33325C21.3333 6.06934 20.7361 6.66675 20 6.66675Z"
      fill="#3D4A65"
    />
    <path
      d="M23.3333 14.6667C18.5547 14.6667 14.6667 18.5547 14.6667 23.3333C14.6667 28.1121 18.5547 32 23.3333 32C28.1121 32 32 28.1121 32 23.3333C32 18.5547 28.1121 14.6667 23.3333 14.6667ZM27.3413 21.8733L23.0081 26.8733C22.7654 27.1533 22.4172 27.3186 22.0481 27.3333C22.032 27.3333 22.0161 27.3333 22 27.3333C21.6467 27.3333 21.3081 27.1934 21.0574 26.9426L18.7241 24.6094C18.2026 24.0879 18.2026 23.2454 18.7241 22.7241C19.2454 22.2026 20.0879 22.2026 20.6094 22.7241L21.9307 24.0454L25.3254 20.1279C25.8081 19.5706 26.6506 19.5134 27.2068 19.9934C27.7627 20.4746 27.8228 21.3174 27.3413 21.8733Z"
      fill="url(#paint0_linear_1646_26712)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1646_26712"
        x1="28.5532"
        y1="15.5782"
        x2="6.33525"
        y2="41.3518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7DD06F" />
        <stop offset="1" stop-color="#65A25B" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'cusAttendancePresent',
}
</script>

<style></style>
