<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.76837e-07 5.99998C4.76837e-07 5.78492 0.0840335 5.56988 0.251746 5.40591L5.53196 0.24617C5.86785 -0.0820567 6.41243 -0.0820567 6.74819 0.24617C7.08394 0.574264 7.08394 1.10632 6.74819 1.43458L2.07596 5.99998L6.74802 10.5654C7.08377 10.8936 7.08377 11.4256 6.74802 11.7537C6.41227 12.0821 5.86769 12.0821 5.5318 11.7537L0.251583 6.59405C0.0838428 6.43 4.76837e-07 6.21496 4.76837e-07 5.99998Z"
      fill="#3D4A65"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusChevronLeft',
}
</script>

<style></style>
