<template>
  <svg
    viewBox="0 0 31 31"
    fill="none"
    class="svg"
    :style="`height: ${vHeight}; width: ${vWidth}`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4352 3.91319V6.35894C8.58838 6.86915 6.68343 7.13878 4.76758 7.16115C1.25222 7.02418 0.652199 5.21759 0.652199 5.21759L3.261 4.56539L1.44136 3.98494C1.00889 3.76686 0.645271 3.43325 0.390849 3.02111C0.136427 2.60897 0.00114175 2.13441 0 1.65006V0C0 0 6.52199 3.91319 10.4352 3.91319Z"
      fill="#FDDD15"
    />
    <path
      d="M29.3494 5.21759C29.3494 5.21759 28.7494 7.02418 25.234 7.16115C23.3182 7.13878 21.4132 6.86915 19.5664 6.35894V3.91319C23.4796 3.91319 30.0016 0 30.0016 0V1.65006C30.0005 2.13441 29.8652 2.60897 29.6107 3.02111C29.3563 3.43325 28.9927 3.76686 28.5602 3.98494L26.7406 4.56539L29.3494 5.21759Z"
      fill="#FDDD15"
    />
    <path
      d="M19.5649 3.91319V6.35894C16.1865 5.40021 14.9995 3.91319 14.9995 3.91319C14.9995 3.91319 13.8125 5.40021 10.4341 6.35894V0.815249L13.1733 2.6088L14.9995 0L16.8256 2.6088L19.5649 0.815249V3.91319Z"
      fill="#FFDC56"
    />
    <path
      d="M15.7699 26.9161L15.0003 27.3922L14.2503 26.8835L11.0806 24.7378C9.66481 23.7595 8.50945 22.4501 7.71494 20.9236C6.92042 19.3971 6.51081 17.6996 6.5217 15.9788V9.13066C8.61097 9.19008 10.691 8.83084 12.6393 8.0741L15.0003 10.4351L17.3612 8.0741C19.3096 8.83084 21.3896 9.19008 23.4789 9.13066V16.207C23.4928 18.0008 23.0484 19.7685 22.1878 21.3424C21.3272 22.9163 20.0789 24.2444 18.5613 25.2008L15.7699 26.9161Z"
      fill="#E9E9FF"
    />
    <path
      d="M25.2332 7.16104C25.5137 7.16756 25.7941 7.17408 26.0876 7.17408V16.0179C26.0883 18.2899 25.4952 20.5226 24.3672 22.4949C23.2393 24.4671 21.6155 26.1104 19.6569 27.2618L17.022 28.8075L16.9568 28.6967L15.7698 26.9162L18.5612 25.2009C20.0789 24.2445 21.3271 22.9164 22.1877 21.3424C23.0483 19.7685 23.4927 18.0008 23.4788 16.207V9.13068C21.3895 9.1901 19.3095 8.83087 17.3612 8.07412C16.4613 7.75314 15.6516 7.22081 15.0002 6.52188C14.3488 7.22081 13.5392 7.75314 12.6393 8.07412C10.691 8.83087 8.61091 9.1901 6.52164 9.13068V15.9788C6.51075 17.6996 6.92037 19.3971 7.71488 20.9236C8.5094 22.4501 9.66475 23.7595 11.0805 24.7378L14.2502 26.8835L13.0436 28.6967L13.0241 28.7227L9.86742 26.6814C8.04007 25.4978 6.53794 23.8759 5.49789 21.9632C4.45784 20.0506 3.91294 17.9081 3.91284 15.7309V7.17408C4.20633 7.17408 4.48678 7.16756 4.76722 7.16104C6.68308 7.13867 8.58803 6.86904 10.4348 6.35883C13.8132 5.4001 15.0002 3.91309 15.0002 3.91309C15.0002 3.91309 16.1872 5.4001 19.5656 6.35883C21.4124 6.86904 23.3174 7.13867 25.2332 7.16104Z"
      fill="#5E98EB"
    />
    <path
      d="M14.9995 3.91316C14.9995 3.91316 16.1865 5.40018 19.5649 6.35891V5.05451C16.1865 4.09578 14.9995 2.60876 14.9995 2.60876C14.9995 2.60876 13.8125 4.09578 10.4341 5.05451V6.35891C13.8125 5.40018 14.9995 3.91316 14.9995 3.91316Z"
      fill="#FFB337"
    />
    <path
      d="M23.4786 8.15248C21.5197 8.2079 19.5691 7.87543 17.7392 7.17418C17.003 6.90679 16.3338 6.48239 15.7781 5.93044C15.687 5.81418 15.571 5.71991 15.4386 5.65465C15.3061 5.58939 15.1607 5.5548 15.013 5.55347C14.8647 5.55467 14.7185 5.58787 14.5842 5.65079C14.4499 5.71371 14.3308 5.80487 14.235 5.91805C13.6777 6.47735 13.0035 6.90633 12.2607 7.17418C10.4308 7.87528 8.48029 8.20775 6.52139 8.15248C6.26193 8.15248 6.0131 8.25555 5.82963 8.43902C5.64617 8.62248 5.5431 8.87132 5.5431 9.13078V15.9789C5.52798 17.8603 5.97493 19.7168 6.84474 21.3852C7.71454 23.0536 8.98059 24.483 10.5318 25.5479L14.4508 28.2017C14.6072 28.3078 14.7909 28.3664 14.9797 28.3703C15.1686 28.3742 15.3546 28.3234 15.5152 28.2239L19.073 26.0345C20.7302 24.9875 22.0939 23.5362 23.0357 21.817C23.9776 20.0979 24.4666 18.1674 24.4569 16.2071V9.13078C24.4569 8.87132 24.3538 8.62248 24.1703 8.43902C23.9869 8.25555 23.738 8.15248 23.4786 8.15248ZM23.4786 16.2071C23.4925 18.0009 23.0481 19.7686 22.1875 21.3425C21.3269 22.9165 20.0786 24.2446 18.561 25.201L15.7696 26.9163L15 27.3924L14.25 26.8836L11.0803 24.7379C9.66451 23.7596 8.50915 22.4502 7.71464 20.9237C6.92012 19.3972 6.5105 17.6997 6.52139 15.9789V9.13078C8.61067 9.1902 10.6907 8.83096 12.639 8.07422C13.5389 7.75324 14.3486 7.22091 15 6.52198C15.6514 7.22091 16.4611 7.75324 17.3609 8.07422C19.3093 8.83096 21.3893 9.1902 23.4786 9.13078V16.2071Z"
      fill="#457EFF"
    />
    <path
      d="M15.0003 6.52197C14.3489 7.2209 13.5392 7.75323 12.6393 8.07421C10.691 8.83096 8.61097 9.19019 6.5217 9.13077V15.9789C6.51081 17.6997 6.92042 19.3972 7.71494 20.9237C8.50945 22.4502 9.66481 23.7596 11.0806 24.7379L14.2503 26.8836L15.0003 27.3923L15.7699 26.9162L18.5613 25.201C20.0789 24.2446 21.3272 22.9164 22.1878 21.3425C23.0484 19.7686 23.4928 18.0009 23.4789 16.2071V9.13077C21.3896 9.19019 19.3096 8.83096 17.3612 8.07421C16.4614 7.75323 15.6517 7.2209 15.0003 6.52197ZM22.5006 16.2071C22.5114 17.8331 22.1086 19.4351 21.3299 20.8625C20.5512 22.2899 19.4223 23.4958 18.0493 24.3668L15.2579 26.0821L15.0277 26.2249L11.6323 23.9259C10.3458 23.0406 9.29625 21.853 8.57576 20.4674C7.85527 19.0818 7.48588 17.5405 7.49999 15.9789V10.0895C9.38983 10.0507 11.2576 9.67525 13.0156 8.98076C13.7236 8.69288 14.3908 8.31356 15.0003 7.85246C15.6085 8.31197 16.2744 8.68957 16.981 8.97555C18.7399 9.67235 20.6091 10.0496 22.5006 10.0895V16.2071Z"
      fill="#C9D1F6"
    />
    <path
      d="M17.0234 28.8077L15.0016 30.0012L13.0254 28.7229L13.045 28.6968L14.2515 26.8837L15.0016 27.3924L15.7711 26.9163L16.9582 28.6968L17.0234 28.8077Z"
      fill="#FFDC56"
    />
    <path
      d="M17.3611 8.07421L15.0001 10.4352L12.6392 8.07421C13.539 7.75323 14.3487 7.2209 15.0001 6.52197C15.6515 7.2209 16.4612 7.75323 17.3611 8.07421Z"
      fill="#FFDC56"
    />
    <path
      d="M16.4843 7.64767C15.9258 7.36541 15.4232 6.98397 15.0012 6.52197C14.5791 6.98397 14.0766 7.36541 13.5181 7.64767L15.0012 9.13077L16.4843 7.64767Z"
      fill="#FF8F75"
    />
    <path
      d="M13.3769 8.8125C13.2575 8.86728 13.1447 8.92337 13.0149 8.97751C11.5908 9.54577 10.0881 9.89305 8.55908 10.0073C8.88631 13.0679 10.1894 15.9421 12.2759 18.205C14.3623 20.468 17.1214 21.9997 20.1454 22.5739C21.6669 20.8018 22.5023 18.5428 22.4998 16.2071V10.0895C20.6084 10.0496 18.7392 9.67236 16.9803 8.97555C16.8498 8.92207 16.7403 8.86663 16.6222 8.8125L14.9995 10.4352L13.3769 8.8125Z"
      fill="white"
    />
    <path
      d="M16.4049 27.8678L15.7703 26.9163L15.0007 27.3924L14.2507 26.8837L13.6331 27.8117L15.0007 28.6968L16.4049 27.8678Z"
      fill="#FF8F75"
    />
    <path
      d="M30.0016 1.65006V0C30.0016 0 23.4796 3.9132 19.5664 3.9132V6.35894C20.2218 6.54782 20.8872 6.70024 21.5595 6.81548C21.6441 6.19298 21.9513 5.62215 22.4241 5.20852C22.897 4.7949 23.5036 4.56645 24.1318 4.56539H26.7406L28.5602 3.98494C28.9927 3.76686 29.3563 3.43325 29.6107 3.02111C29.8652 2.60897 30.0005 2.13441 30.0016 1.65006Z"
      fill="#FFA41F"
    />
    <path
      d="M0 1.65006V0C0 0 6.52199 3.9132 10.4352 3.9132V6.35894C9.77976 6.54782 9.11436 6.70024 8.44207 6.81548C8.35745 6.19298 8.05033 5.62215 7.57748 5.20852C7.10463 4.7949 6.49802 4.56645 5.86979 4.56539H3.261L1.44136 3.98494C1.00889 3.76686 0.645271 3.43325 0.390849 3.02111C0.136427 2.60897 0.00114175 2.13441 0 1.65006Z"
      fill="#FFA41F"
    />
    <path
      d="M18.5838 4.25162C18.7939 4.04142 18.7271 3.63406 18.4346 3.34177C18.1421 3.04948 17.7347 2.98293 17.5246 3.19313C17.3146 3.40333 17.3814 3.81068 17.6739 4.10297C17.9664 4.39527 18.3738 4.46182 18.5838 4.25162Z"
      fill="#EAF6FF"
    />
    <path
      d="M12.2828 3.66147C12.3879 3.55624 12.3546 3.35246 12.2083 3.20631C12.0621 3.06017 11.8583 3.02699 11.7531 3.13222C11.648 3.23745 11.6813 3.44123 11.8276 3.58738C11.9738 3.73352 12.1776 3.76669 12.2828 3.66147Z"
      fill="#EAF6FF"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusLevelBadge',
  props: {
    vHeight: { type: String, default: '31px' },
    vWidth: { type: String, default: '31px' },
  },
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
