<template>
  <v-card class="wrapper">
    <div class="top">
      <img :src="getImgSrc('images/challenges/lightbulb.png')" />
      <div class="title-wrapper">
        <div class="title-text">Weekly Challenge</div>
        <div v-if="activeQuestion && !correctlyAnswered && !outOfAttempts">
          Earn up to
          <img class="icon-img" :src="getImgSrc('images/experience.svg')" />
          {{ activeQuestion.xpReward }} and
          <img class="icon-img" :src="getImgSrc('images/goldCoin.svg')" />
          {{ activeQuestion.currencyReward }}
        </div>
        <div v-else class="earn-text">{{ subText }}</div>
      </div>
    </div>
    <p class="sale-text">{{ mainText }}</p>
    <StreakCard lite />
    <ChallengeTimer v-if="showTimer" class="mb-4" v-bind="attributes" />
    <PrimaryButton full-width to="/challenges" @click.prevent="onNavigation">{{
      btnText
    }}</PrimaryButton>
  </v-card>
</template>

<script setup lang="ts">
import { useChallengeStore } from '@/stores/challengeStore'
import { getImgSrc } from '@/utils/helperFunctions'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { getRandomFailureMessage, getRandomMotivatorMessage } from './challengeText'
import { mixpanel } from '@/utils/helperFunctions'

const props = defineProps<{ location: 'Top Nav' | 'Dashboard' }>()

const { activeQuestion, getChallengeStatus, getActiveAgeGroup } = storeToRefs(
  useChallengeStore()
)
const showTimer = computed(() => activeQuestion.value?.showTimer)
const correctlyAnswered = computed(() => activeQuestion.value?.isCorrect)
const outOfAttempts = computed(() => activeQuestion.value?.attemptsRemaining === 0)
const notYetFinished = computed(() =>
  [true, false].includes(!activeQuestion.value?.isCorrect)
)

const subText = computed(() => {
  if (!activeQuestion.value) return ''
  if (correctlyAnswered.value) return 'You did it!'
  if (outOfAttempts.value) return 'No attempts remaining'
  return ''
})

const mainText = computed(() => {
  if (correctlyAnswered.value) return getRandomMotivatorMessage()
  if (outOfAttempts.value) return getRandomFailureMessage()
})

const btnText = computed(() => {
  const q = activeQuestion.value
  if (!q) return 'Review Challenges'
  const showStart = q.attemptsRemaining > 0 && notYetFinished.value
  if (showStart) return 'Start Now!'
  return 'Review Challenges'
})

const attributes = {
  hideSec: false,
  color: '#FFEFCD',
  fullWidth: true,
}

function onNavigation() {
  mixpanel('Challenge Nav Clicked', {
    location: props.location,
    challengeStatus: getChallengeStatus.value,
    ageGroup: getActiveAgeGroup.value,
  })
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 282px;
  max-width: 282px;
  padding: 20px;
  font-size: 14px;

  .icon-img {
    max-height: 12px;
  }

  .top {
    display: flex;
    margin-bottom: 16px;

    .title-wrapper {
      margin-left: 8px;
      font-weight: 500;

      .title-text {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
</style>
