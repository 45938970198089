import client from '@/utils/ajax/http'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { refreshPreview } from '@/utils/helperFunctions'
import { Editor, Doc } from 'codemirror'

interface FileTreeObj {
  /**Array of child nodes. */
  children: FileTreeObj[]
  ext: string
  fullPath: string
  key: number
  name: string
  path: string
  type: string
}

/**The editor store is anything related to the editor, the viewer, or files/folders. */
export const useEditorStore = defineStore('editor', {
  state: () => ({
    isShowingPreviewPanel: false,
    settings: {
      autoSave: true,
    },
    outputHeight: 0,
    codeFontSize: 16,
    /**POJO that stores the CodeMirror.Doc of all codemirror files received from server. */
    fileStore: {} as Record<string, Doc>,
    /**The codemirror instance. */
    editor: null as Editor,
    /**Name of the currently selected file in tree selector. */
    currentFile: '',
    /**Type of the currently selected file in tree selector. */
    currentFileType: '',
    /**The codemirror document instance. */
    // currentDocument: null as Doc,
    /**Whether or not the editor is saving. Not affected by auto saving. */
    editorIsSaving: false,
    /**Whether or not the editor is auto saving. */
    editorIsAutoSaving: false,

    /** Array of objects that make up the editor's tree selector. */
    fileTree: [] as FileTreeObj[],
    /** Array of folders in the editor's tree selector. */
    folderList: [] as String[],
    /** Array of file paths in the editor's tree selector. */
    fileList: [] as String[],
    /** DOM element that contains the code/image viewer. */
    viewer: null,

    // deprecated?
    width: 0,
    height: 0,

    /**Path to add or upload to in the file tree. */
    pathToAdd: '',
    newFileDialog: false,
    newFolderDialog: false,
    addProjectDialog: false,
    fileUploadDialog: false,
  }),
  getters: {
    /**Returns the editor instance's current document, or null. */
    currentDocument: (state) => {
      if (state.editor) return state.editor.getDoc()
      else return null
    },
  },
  actions: {
    saveCurrent() {
      return new Promise((resolve, reject) => {
        if (this.editorIsAutoSaving) return reject('editor is auto savin')
        if (this.editorIsSaving) return reject('editor is saving manually')
        if (this.currentDocument == null) return reject('No current Document')

        if (this.currentDocument.isClean()) {
          return reject('Document is clean, no need to save')
        }
        client
          .post(`/editor/set/${this.currentFile}`, this.currentDocument.getValue(), {
            headers: { 'Content-Type': 'text/plain' },
          })
          .then(() => {
            refreshPreview(this.isShowingPreviewPanel)
            resolve('editorStore: Save successful')
          })
          .catch((err) => reject(err))
          .finally(() => this.currentDocument.markClean())
      })
    },
    resize() {
      this.width = window.innerWidth || document.body.clientWidth
      this.height = window.innerHeight || document.body.clientHeight
      const el = document.getElementById('code')
      if (this.editor) {
        el.style.height = `${88 - this.outputHeight}vh`
        this.editor.getWrapperElement().style.height = `${88 - this.outputHeight}vh`
        this.editor.refresh()
      }
      if (document.getElementById('output')) {
        document.getElementById('output').style.height = `${this.outputHeight}vh`
      }
    },
    /** Adds the CodeMirror.Doc to the FileStore with the fileName as the key */
    setFileStoreProperty(fileName: string, document: Doc) {
      this.fileStore[fileName] = document
    },
    setAutosave(val: boolean) {
      this.settings.autoSave = val
      localStorage.setItem('editorSettings', JSON.stringify(this.settings))
    },
    setSettingsFromLocalStorage() {
      const v = localStorage.getItem('editorSettings')
      if (v !== null) this.settings = JSON.parse(v)
    },
    setCurrentFile(path: string, type?: string) {
      this.currentFile = path
      if (type) this.currentFileType = type
    },
    getFiles() {
      function makeFolderList(data) {
        let folders = []
        for (let node of data) {
          if (node.type === 'dir') {
            folders.push(node.path + '/')
            let sub = makeFolderList(node.children)
            folders = folders.concat(sub)
          }
        }
        return folders
      }

      function makeFileList(data) {
        let files = []
        for (let node of data) {
          if (node.type === 'file') {
            files.push(node.path)
          } else {
            let sub = makeFileList(node.children)
            files = files.concat(sub)
          }
        }
        return files
      }

      return new Promise((resolve, reject) => {
        client
          .get('/editor/filetree')
          .then((res) => {
            const folderList = makeFolderList(res.data.tree).sort()
            const fileList = makeFileList(res.data.tree)

            this.fileTree = res.data.tree
            this.folderList = folderList
            this.fileList = fileList
            resolve('success')
          })
          .catch((err) => {
            console.warn('err getting filetree', err)
            reject(err)
          })
      })
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEditorStore, import.meta.hot))
}
