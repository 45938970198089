<template>
  <v-snackbar
    :color="alert.color"
    :multi-line="alert.multiline"
    :right="true"
    :timeout="alert.timeout"
    :top="true"
    v-model="alert.active"
  >
    <span>{{ alert.message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" @click="alert.active = false" dark icon text>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useAccountStore } from '@/stores/accountStore'
import { storeToRefs } from 'pinia'

const { alert } = storeToRefs(useAccountStore())
</script>
