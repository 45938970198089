import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useModalStore = defineStore('modal', () => {
  const surveyReminderModal = ref({ show: false })
  const birthdayModal = ref({ show: false })
  // const welcomeModal = ref({ show: false }) // not used

  return { surveyReminderModal, birthdayModal }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot))
}
