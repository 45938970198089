import { defineStore, acceptHMRUpdate } from 'pinia'
import notificationsData from '@/utils/ajax/notificationsData'
import { AxiosResponse } from 'axios'
import { Entry } from '@/components/ActivityFeed/types'

/**Store used to save bell notifications. */
export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    /** An array of notification objects. */
    notifications: [] as Notification[],
    /** The number of unread notifications. */
    unread: 0,
    /** Id for the fetch notifications interval */
    notificationsIntervalId: null as number,
  }),
  actions: {
    beginNotificationsInterval() {
      this.fetchNotifications()
      const TIMER = 5000 * 60
      this.notificationsIntervalId = window.setInterval(
        this.fetchNotifications,
        TIMER
      )
    },
    endNotificationsInterval() {
      window.clearInterval(this.notificationsIntervalId)
    },
    /** Fetches new notifications from the server. */
    fetchNotifications(): Promise<AxiosResponse<Notification[]>> {
      return new Promise((resolve, reject) => {
        notificationsData
          .getNotifications()
          .then((res) => {
            if (res.data) {
              this.notifications = res.data
            }

            resolve(res)
          })
          .catch((err) => {
            console.warn('Error getting notifications: ', err)
            reject(err)
          })
      })
    },
    getNotificationCount(): Promise<AxiosResponse<{ count: number; now: string }>> {
      return new Promise((resolve, reject) => {
        notificationsData
          .getNotificationCount()
          .then((res) => {
            this.unread = res.data.count
            resolve(res)
          })
          .catch((err) => {
            console.warn('Error getting notification count: ', err)
            reject(err)
          })
      })
    },
    clearNotification(id: number): Promise<number> {
      return new Promise((resolve, reject) => {
        notificationsData
          .updateSingleNotificationStatus(id)
          .then(() => {
            this.notifications = this.notifications.map((notification) => {
              if (notification.id === id) {
                notification.dismissed = true
              }
              return notification
            })
            resolve(id)
          })
          .catch((err) => {
            console.log('Error changing notification status: ', err)
            reject(err)
          })
      })
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}

// types
export type Notification = {
  id: number
  readStatus: number
  message: string
  date: string
  noticeBoxType: number
  slug: string
  buttonLink?: string
  buttonText?: string
  dismissed: boolean
  surveyId?: number
  classId?: number
  feed?: Entry
}
