<template>
  <v-img v-if="avatarImgOrItem" class="emblem" center v-bind="imgProps" />
  <div v-else-if="entry.wizardLevel" class="emblem shield-wrapper">
    <LevelShield v-bind="lvlProps" />
  </div>
  <AchievementBadge v-else v-bind="badgeProps" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import AchievementBadge from '../Achievements/AchievementBadge.vue'
import LevelShield from '../shared/LevelShield.vue'
import type { Entry } from './types'

type Props = { entry: Entry; dashboard?: boolean; notification?: boolean }
const props = defineProps<Props>()

const imgProps = computed(() => ({
  src: avatarImgOrItem.value,
  style: {
    maxWidth: props.dashboard ? '56px' : props.notification ? '50px' : '72px',
    maxHeight: props.dashboard ? '56px' : props.notification ? '50px' : '72px',
    minHeight: props.dashboard ? '56px' : props.notification ? '50px' : '72px',
  },
}))
const badgeProps = computed(() => ({
  achiev: props.entry.achievementAwarded,
  size: props.dashboard ? 56 : props.notification ? 50 : 72,
  style: {
    margin: props.dashboard ? 'auto 0' : '',
    marginLeft: props.dashboard ? '16x' : '',
  },
}))
const lvlProps = computed(() => ({
  level: props.entry.wizardLevel,
  size: props.dashboard ? 56 : props.notification ? 50 : 72,
  fontSize: props.dashboard ? 18 : props.notification ? 50 : 24,
}))
const avatarImgOrItem = computed(
  () => props.entry.avatarItemSrc ?? props.entry.updatedAvatarImg
)
</script>

<style lang="scss" scoped>
.emblem {
  border-radius: 100%;
  margin: auto 0;
  margin-left: 16px;
}

.shield-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: unset;
}
</style>
