<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M7.85714 10.7692C8.29109 10.7692 8.64286 10.4248 8.64286 9.99994C8.64286 9.5751 8.29109 9.23071 7.85714 9.23071H6.28571C5.85178 9.23071 5.5 9.5751 5.5 9.99994C5.5 10.4248 5.85178 10.7692 6.28571 10.7692H7.85714Z"
    />
    <path
      d="M4.71425 9.99994C4.71425 10.4248 4.36248 10.7692 3.92854 10.7692C3.4946 10.7692 3.14282 10.4248 3.14282 9.99994C3.14282 9.5751 3.4946 9.23071 3.92854 9.23071C4.36248 9.23071 4.71425 9.5751 4.71425 9.99994Z"
    />
    <path
      d="M8.64286 16.1539C8.64286 16.5787 8.29109 16.9231 7.85714 16.9231H6.28571C5.85178 16.9231 5.5 16.5787 5.5 16.1539C5.5 15.729 5.85178 15.3846 6.28571 15.3846H7.85714C8.29109 15.3846 8.64286 15.729 8.64286 16.1539Z"
    />
    <path
      d="M3.92854 16.9231C4.36248 16.9231 4.71425 16.5787 4.71425 16.1539C4.71425 15.729 4.36248 15.3846 3.92854 15.3846C3.4946 15.3846 3.14282 15.729 3.14282 16.1539C3.14282 16.5787 3.4946 16.9231 3.92854 16.9231Z"
    />
    <path
      d="M8.64286 3.84614C8.64286 4.27097 8.29109 4.61537 7.85714 4.61537H6.28571C5.85178 4.61537 5.5 4.27097 5.5 3.84614C5.5 3.4213 5.85178 3.0769 6.28571 3.0769H7.85714C8.29109 3.0769 8.64286 3.4213 8.64286 3.84614Z"
    />
    <path
      d="M3.92854 4.61537C4.36248 4.61537 4.71425 4.27097 4.71425 3.84614C4.71425 3.4213 4.36248 3.0769 3.92854 3.0769C3.4946 3.0769 3.14282 3.4213 3.14282 3.84614C3.14282 4.27097 3.4946 4.61537 3.92854 4.61537Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6429 0C20.9447 0 22 1.03319 22 2.30769V5.38462C22 5.97569 21.773 6.51477 21.3998 6.92308C21.773 7.33138 22 7.87046 22 8.46154V11.5385C22 12.1295 21.773 12.6686 21.3998 13.0769C21.773 13.4852 22 14.0243 22 14.6154V17.6923C22 18.9668 20.9447 20 19.6429 20H2.35714C1.05533 20 0 18.9668 0 17.6923V14.6154C0 14.0243 0.226954 13.4852 0.600207 13.0769C0.226954 12.6686 0 12.1295 0 11.5385V8.46154C0 7.87046 0.226954 7.33138 0.600207 6.92308C0.226954 6.51477 0 5.97569 0 5.38462V2.30769C0 1.03319 1.05533 0 2.35714 0H19.6429ZM2.35714 12.3077H19.6429C20.0768 12.3077 20.4286 11.9633 20.4286 11.5385V8.46154C20.4286 8.03669 20.0768 7.69231 19.6429 7.69231H2.35714C1.92321 7.69231 1.57143 8.03669 1.57143 8.46154V11.5385C1.57143 11.9633 1.92321 12.3077 2.35714 12.3077ZM2.35714 13.8462C1.92321 13.8462 1.57143 14.1905 1.57143 14.6154V17.6923C1.57143 18.1172 1.92321 18.4615 2.35714 18.4615H19.6429C20.0768 18.4615 20.4286 18.1172 20.4286 17.6923V14.6154C20.4286 14.1905 20.0768 13.8462 19.6429 13.8462H2.35714ZM19.6429 6.15385H2.35714C1.92321 6.15385 1.57143 5.80946 1.57143 5.38462V2.30769C1.57143 1.88286 1.92321 1.53846 2.35714 1.53846H19.6429C20.0768 1.53846 20.4286 1.88286 20.4286 2.30769V5.38462C20.4286 5.80946 20.0768 6.15385 19.6429 6.15385Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusServers',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
