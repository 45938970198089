<template>
  <article class="notification">
    <NotificationTop :notification="notification" />
    <div class="main">
      <span class="text-here mr-2">{{ getText }}</span>
      <FeedEmoji v-for="emoji in feed.emojis" :emoji="emoji" :key="emoji.name" />
      <div class="entry-box">
        <v-img :src="feed.userAvatar" max-width="40px" max-height="40px" />

        <div class="content">
          <div>
            <span style="font-weight: 600">{{ feed.name }}</span>
            {{ calTime(feed.time) }}
          </div>
          <div v-html="feed.text"></div>
        </div>
        <v-spacer />

        <ActivityFeedEmblem :entry="notification.feed" notification />
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import ActivityFeedEmblem from '../ActivityFeed/ActivityFeedEmblem.vue'

import { Notification, useNotificationsStore } from '@/stores/notificationsStore'
import { computed } from 'vue'
import { User } from '../ActivityFeed/types'
import { calTime } from '@/utils/dayjs'

type Props = { notification: Notification }
const props = defineProps<Props>()

const { clearNotification } = useNotificationsStore()

const feed = computed(() => props.notification.feed)
const emojis = computed(() => props.notification.feed.emojis)
const getText = computed(() => {
  const ids = new Set<number>()
  const users = [] as User[]
  emojis.value.forEach((e) => {
    if (e.selectedBy.length > 0) {
      e.selectedBy.forEach((user) => {
        if (!ids.has(user.id)) {
          users.push(user)
          ids.add(user.id)
        }
      })
    }
  })

  const names = users.map((u) => u.name)
  if (names.length === 1) return `${names[0]} reacted`
  if (names.length < 3) return `${names[0]} and ${names[1]} reacted`
  else return `${names[0]} and ${names.length - 1} others reacted`
})
</script>

<style lang="scss" scoped>
.notification {
  min-width: 100%;
  padding: 20px;
  align-items: center;

  .top {
    font-size: 12px;
    text-align: center;
    line-height: 19.2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-here {
    font-weight: 600;
  }

  .entry-box {
    display: flex;
    font-size: 12px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #d8dbe0;
    padding: 10px 10px;
    width: 100%;
    max-width: 100%;

    .content {
      margin: 0 10px;
    }
  }
}

.notification-timestamp {
  font-size: 12px;
  color: var(--v-common-lighten2);
}
</style>
