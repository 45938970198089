// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import icons from './vuetifyIcons'

Vue.use(Vuetify)

const cusColors = {
  primary: {
    mainYellow: '#0C1D3F',
    darkBlue: '#0C1D3F',
    grey: '#3D4A65',
    lightGrey: '#D8DBE0',
  },
  error: '#FF6050',
}

const opts = {
  icons,
  theme: {
    themes: {
      light: {
        // primary: '#ffc844',
        primary: cusColors.primary.darkBlue,
        secondary: cusColors.primary.grey,
        accent: '#011E41',
        error: cusColors.error,
        success: '#2BBE2A',
        anchor: cusColors.primary.darkBlue,
        button: cusColors.primary.darkBlue,
        // Externally defined variables and classes
        tertiary: '#cd7138',
        quaternary: '#2962FF',
        border: '#dddcdc',
        color: {
          base: '#303030',
          lighten1: '#aaaaaa',
          lighten2: '#ededed',
          lighten3: '#ffe3a1',
          lighten4: '#ffffff',
          darken1: '#303030',
          darken2: '#303030',
          darken3: '#727272',
          darken4: '#ededed',
          darken5: '#ffffff',
        },
        common: {
          base: '#303030',
          lighten1: '#ffa844',
          lighten2: '#aaaaaa',
          lighten3: '#cd7138',
          lighten4: '#2962ff',
        },

        background: {
          base: '#E5E5E5', // whole app background
          lighten1: '#FFC84450', // #FFC844 50% opacity => // mostly used in dropdown selected item background
          lighten2: '#EDEDED', // grey background for text sections
          lighten3: '#FFFFFF', // card background color, different in dark mode
          lighten4: '#ededed',
          lighten5: '#EDEDED', // card hover
          darken1: '#fff7e3', // yellowish card
          darken2: '#dddcdc',
        },

        text: cusColors.primary.darkBlue,

        'dark-primary': '#ffa844',
        'title-background': '#fff7e3',
        'secondary-btn-color': '#303030',
        'blue-link-color': '#2962FF',
        'input-label': '#aaaaaa',
        tabColor: '#FFA844',
      },

      dark: {
        // primary: '#ffc844',
        primary: 'white',
        secondary: colors.grey.darken1,
        accent: '#011E41',
        error: '#ff4444',
        success: '#2BBE2A',
        anchor: 'black',

        // Externally defined variables and classes
        tertiary: '#cd7138',
        quaternary: '#2962FF',
        border: '#5F6368',
        color: {
          base: '#303030',
          lighten1: '#aaaaaa',
          lighten2: '#1e1e1e',
          lighten3: '#C7992C',
          lighten4: '#202124',
          darken1: '#aaaaaa',
          darken2: '#ffffff',
          darken3: '#ffffff',
          darken4: '#313235',
          darken5: '#313235',
        },
        common: {
          base: '#303030',
          lighten1: '#ffa844',
          lighten2: '#aaaaaa',
          lighten3: '#cd7138',
          lighten4: '#2962ff',
        },

        background: {
          base: '#202124', // whole app background
          lighten1: '#ffc84450', // #FFC844 50% opacity => // mostly used in dropdown selected item background
          lighten2: '#1E1E1E', // grey background for text sections
          lighten3: '#313235', // cards background color
          lighten4: '#313235',
          lighten5: '#202124', // card hover
          darken1: '#383223',
          darken2: '#6c6c6c',
        },

        text: '#FFFFFF',

        'dark-primary': '#ffa844',
        'title-background': '#383223',
        'secondary-btn-color': '#ffffff',
        'blue-link-color': '#8AB4F8',
        'input-label': '#979797',
        tabColor: '#FFC844',
      },
    },
    options: {
      customProperties: true,
    },
  },
}

export default new Vuetify(opts)
