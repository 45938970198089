<template>
  <div v-if="timerDuration" class="timer" :class="true ? 'fw' : ''">
    <TimerBox label="Days" :display="timerDuration.days()" v-bind="attrs" />
    <span class="colon">:</span>
    <TimerBox label="Hrs" :display="timerDuration.hours()" v-bind="attrs" />
    <span class="colon">:</span>
    <TimerBox label="Mins" :display="timerDuration.minutes()" v-bind="attrs" />
    <template v-if="!hideSec">
      <span class="colon">:</span>
      <TimerBox label="Sec" :display="timerDuration.seconds()" v-bind="attrs" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useChallengeStore } from '@/stores/challengeStore'

const props = defineProps({
  color: { default: '#fff' },
  hideSec: { default: false },
  fullWidth: { default: false },
})

const attrs = {
  color: props.color,
  fullWidth: props.fullWidth,
}

const { timerDuration } = storeToRefs(useChallengeStore())
</script>

<style lang="scss" scoped>
.timer {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.fw {
  display: flex;
  justify-content: space-between;

  .colon {
    margin: 0 6px;
  }
}
</style>
