<template>
  <v-card class="text-center pt-10 pb-10">
    <img
      :class="classString ? classString : 'error-cross'"
      alt="Info cross image"
      :style="styleString"
      :src="imgUrl"
    />
    <v-card-title class="justify-center">{{ title }}</v-card-title>

    <!-- if it's not an array show it -->
    <v-card-text v-if="!Array.isArray(message)" v-html="message"></v-card-text>
    <!-- if it is an array, it's an array of strings -->
    <template v-else>
      <v-card-text v-for="msg in message" v-html="msg" :key="msg" />
    </template>

    <v-card-actions class="justify-center">
      <v-row class="mx-0">
        <v-col cols="12">
          <v-btn
            depressed
            :loading="loading"
            class="primary-btn px-14"
            @click="$emit('ok')"
            >{{ primaryBtnText }}</v-btn
          >
        </v-col>
        <v-col v-if="secondaryBtnText">
          <v-btn text @click="$emit('close')">{{ secondaryBtnText }}</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SuccessInfoErrorCard',
  props: {
    title: { type: String, default: 'Error' },
    classString: { type: String, default: null }, // can override class
    styleString: { type: String, default: undefined }, // can override style
    type: { type: String, default: 'error' },
    message: { type: [String, Array], default: 'Please try again later' },
    primaryBtnText: { type: String, default: 'Try again' },
    secondaryBtnText: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    imgSrc: { type: String, default: null }, // override for img src
  },
  computed: {
    getImgSrcString() {
      if (this.$vuetify.theme.dark) return `${this.type}-dark.svg`
      else return `${this.type}-light.svg`
    },
    imgUrl() {
      if (this.imgSrc) return this.$img(`images/${this.imgSrc}`)
      return this.$img(`illustrations/${this.getImgSrcString}`)
    },
  },
}
</script>
