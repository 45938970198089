<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M12.3284 3.42024L13.2254 0.774047L10.5792 1.67103L8.3393 0V2.79431L6.09375 4.40729L8.76231 5.23715L9.59216 7.90571L11.2051 5.66016H13.9994L12.3284 3.42024Z"
    />
    <path
      d="M8.38269 6.77727L8.10748 5.8924L7.22261 5.61719L0 12.8398L1.16009 13.9999L8.38269 6.77727Z"
    />
  </svg>
</template>

<script>
export default { name: 'cusWand' }
</script>

<style scoped>
.svg {
  fill: currentColor;
}
</style>
