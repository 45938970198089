/**
 * src/edit/src/utils/ajax/http.js
 *
 * Base Axios client for all HTTP requests for the Learn Backend.
 */

import axios from 'axios'
import { useAccountStore } from '@/stores/accountStore'

const BASE_URL = import.meta.env.VITE_API_BASE_URL + '/learn/api'

export const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

client.interceptors.request.use(function (config) {
  config.headers['X-Selected-Class-Id'] = useAccountStore().selectedClassId
  return config
})

export default client
