<template>
  <svg
    width="26"
    height="16"
    viewBox="0 0 26 16"
    class="svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2309 0.290909C24.1237 0.116364 23.9093 0 23.7485 0C19.3526 0.407273 15.868 4.07273 15.0639 4.88727H10.9361C10.132 4.01454 6.64742 0.407273 2.25155 0C2.03711 0 1.82268 0.116364 1.76907 0.290909C1.71546 0.407273 0 3.54909 0 7.91273V8.01251C0 7.98545 0 8.03636 0 8.08727C0 12.4509 1.66186 15.5927 1.76907 15.7091C1.87629 15.8836 2.03711 16 2.25155 16H2.30515C6.80825 15.5927 10.3464 11.7527 11.0433 11.0545H15.0639C15.6536 11.7527 19.1918 15.5345 23.6948 16H23.7485C23.9093 16 24.1237 15.8836 24.2309 15.7091C24.2845 15.5927 26 12.4509 26 8.08727V7.97091V7.91273C26 3.54909 24.3381 0.407273 24.2309 0.290909ZM10.1856 7.38909H8.57732C8.25567 7.38909 8.04124 7.62182 8.04124 7.97091C8.04124 8.32 8.25567 8.55273 8.57732 8.55273H10.1856V10.24C9.48866 10.9964 6.37938 14.2545 2.51959 14.7782C2.09072 13.9055 1.07216 11.3455 1.07216 8.02909C1.07216 8.02909 1.07216 8.02909 1.07216 7.97091C1.07216 4.59636 2.09072 2.03636 2.51959 1.16364C6.32577 1.68727 9.48866 4.94545 10.1856 5.70182V7.38909ZM14.7423 9.83273H11.2041V5.99273H14.7423V9.83273ZM24.8742 7.91273C24.8742 7.97091 24.8742 7.97091 24.8742 8.02909V8.08727C24.8742 11.4036 23.8557 13.9636 23.4268 14.8364C19.6206 14.3127 16.4577 11.0545 15.7608 10.2982V8.55273H17.3691C17.6907 8.55273 17.9052 8.32 17.9052 7.97091C17.9052 7.62182 17.6907 7.38909 17.3691 7.38909H15.7608V5.70182C16.4577 4.94545 19.6206 1.68727 23.4268 1.16364C23.8557 2.03636 24.9278 4.59636 24.8742 7.91273Z"
    />
  </svg>
</template>

<script>
export default { name: 'neck' }
</script>

<style>
.svg {
  fill: currentColor;
}
</style>
