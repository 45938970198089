<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div class="shield" :style="style" v-bind="attrs" v-on="on">
        <span class="mt-1">{{ level }}</span>
      </div>
    </template>
    <span>Experience Level</span>
  </v-tooltip>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  level: { type: Number },
  size: { default: 56 },
  fontSize: { default: 20 },
})

const style = computed(() => ({
  minHeight: props.size + 'px',
  maxHeight: props.size + 'px',
  minWidth: props.size + 'px',
  fontSize: props.fontSize + 'px',
  fontWeight: 700,
}))
</script>

<style lang="scss" scoped>
.shield {
  background-image: url('../../assets/icons/new-shield.png');
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
