<template>
  <svg
    width="25"
    height="28"
    viewBox="0 0 25 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5355 0.0654089C14.8129 0.698035 15.0543 5.75155 17.0744 11.3528C19.0944 16.954 22.1281 20.982 23.8507 20.3494C25.5732 19.7168 25.3318 14.6633 23.3122 9.06161C21.2918 3.46036 18.258 -0.567218 16.5355 0.0654089Z"
      fill="#FFB337"
    />
    <path
      d="M18.7194 12.5201C16.8471 6.8677 16.7391 1.8096 18.4775 1.22234C18.6173 1.17489 18.7654 1.16157 18.9198 1.17323C18.0219 0.251758 17.1855 -0.173184 16.5362 0.0652999C14.8137 0.697926 15.0551 5.75145 17.0751 11.3527C18.6562 15.737 20.8582 19.1565 22.5665 20.1325C21.2193 18.5035 19.7951 15.7682 18.7194 12.5201Z"
      fill="#FFA41F"
    />
    <path
      d="M7.53711 19.3734L11.9286 18.0083C12.4118 19.3314 11.2445 19.7526 11.4629 21.402C11.5453 22.0259 12.0311 21.8498 12.2838 22.5416L12.3562 24.2284C12.6089 24.9197 13.5168 24.643 13.7691 25.333C13.9637 25.8662 13.2955 26.4955 13.2955 26.4955C13.2955 26.4955 11.8144 28.162 10.7629 27.9872C9.29897 27.7424 7.53711 19.3734 7.53711 19.3734Z"
      fill="#3D4A65"
    />
    <path
      d="M11.4629 21.4021C11.2495 19.7893 12.3566 19.3481 11.9545 18.0933L10.7495 18.375L7.53711 19.3735L7.61911 19.7493L11.4884 21.5078L11.4629 21.4021Z"
      fill="#0C1D3F"
    />
    <path
      d="M1.63409 12.1643L3.92898 11.3411C3.9407 12.3225 4.32813 14.0073 5.01053 15.8548C5.68875 17.6911 6.48663 19.2185 7.11464 19.981L7.1159 19.9843L4.82518 20.8059C3.27419 21.3624 1.30314 19.8778 0.422415 17.4929C-0.458724 15.1069 0.0830968 12.7204 1.63409 12.1643Z"
      fill="#3D4A65"
    />
    <path
      d="M5.03104 20.7324L7.11591 19.9844L7.11465 19.9811C6.48664 19.2186 5.68876 17.6912 5.01054 15.8549C4.58378 14.6991 4.27249 13.6074 4.09593 12.7113L3.5767 11.4673L1.63368 12.1644L1.5546 12.1998C1.46799 13.0172 1.82237 14.6983 2.51733 16.5574C3.37211 18.8461 4.4432 20.6017 5.03104 20.7324Z"
      fill="#0C1D3F"
    />
    <path
      d="M17.5162 11.2124C15.6497 6.21217 15.0426 1.733 15.9857 0.519775C15.8589 0.646301 15.7054 0.824435 15.5292 1.08206C13.9515 3.38241 11.3348 8.30608 2.36194 11.4909L2.36696 11.5029C1.81802 11.6977 2.12638 13.869 3.05438 16.3525C3.98112 18.8351 5.17564 20.691 5.72457 20.4958C14.6979 17.3114 19.9655 19.5131 22.6884 20.2869C23.5461 20.5299 23.8607 20.3464 23.8607 20.3464C22.4286 20.8541 19.5889 16.7654 17.5162 11.2124Z"
      fill="#FFC945"
    />
    <path
      d="M17.5163 11.2126C17.8962 12.2306 18.3021 13.1987 18.7205 14.099C20.1284 13.587 20.8547 12.0375 20.3413 10.6358C19.828 9.23275 18.2686 8.50981 16.8578 9.02007L16.7795 9.05295C16.9984 9.75674 17.2419 10.478 17.5163 11.2126Z"
      fill="#EBEDEE"
    />
    <path
      d="M15.5606 3.02947C15.5054 1.84704 15.6405 0.963446 15.9857 0.519775C15.8589 0.646301 15.7054 0.824435 15.5292 1.08206C13.9514 3.38241 11.3348 8.30608 2.36191 11.4909L2.36693 11.5029C2.11631 11.592 2.04602 12.0964 2.13054 12.8518L2.86147 12.6129C10.8863 9.77402 13.8749 5.56414 15.5606 3.02947Z"
      fill="#FFDC56"
    />
    <path
      d="M18.7192 12.5204C18.3004 11.2555 17.9707 10.0215 17.7293 8.85986C17.4393 8.86611 17.1452 8.91647 16.8578 9.02052L16.7795 9.0534C16.9984 9.75678 17.2419 10.4781 17.5163 11.2127C17.8962 12.2307 18.3021 13.1988 18.7205 14.099C18.8899 14.0374 19.0493 13.96 19.1983 13.8701C19.0326 13.4314 18.8719 12.9815 18.7192 12.5204Z"
      fill="#C8CACB"
    />
  </svg>
</template>

<script>
export default { name: 'cusMegaphone' }
</script>
