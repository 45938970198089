<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6667 6.33325V9.33325H0V6.33325C0 4.30664 1.63989 2.66675 3.66675 2.66675H23C25.0266 2.66675 26.6667 4.30664 26.6667 6.33325Z"
      fill="#457EFF"
    />
    <path
      d="M12.6667 23.3333C12.6667 17.4519 17.4519 12.6667 23.3333 12.6667C24.4988 12.6667 25.616 12.8613 26.6667 13.208V9.33325H0V21.6667C0 23.6934 1.63989 25.3333 3.66675 25.3333H12.8613C12.7373 24.6841 12.6667 24.0173 12.6667 23.3333Z"
      fill="#D8DBE0"
    />
    <path
      d="M6.66675 6.66675C5.93066 6.66675 5.33325 6.06934 5.33325 5.33325V1.33325C5.33325 0.597412 5.93066 0 6.66675 0C7.40259 0 8 0.597412 8 1.33325V5.33325C8 6.06934 7.40259 6.66675 6.66675 6.66675Z"
      fill="#3D4A65"
    />
    <path
      d="M20 6.66675C19.2639 6.66675 18.6667 6.06934 18.6667 5.33325V1.33325C18.6667 0.597412 19.2639 0 20 0C20.7361 0 21.3333 0.597412 21.3333 1.33325V5.33325C21.3333 6.06934 20.7361 6.66675 20 6.66675Z"
      fill="#3D4A65"
    />
    <circle cx="23" cy="23" r="9" fill="#3D4A65" />
    <path
      d="M26.7296 20.5757L24.3057 22.9999L26.7296 25.4241C27.0902 25.7847 27.0902 26.369 26.7296 26.7297C26.5495 26.9099 26.3133 27 26.0772 27C25.8408 27 25.6045 26.91 25.4245 26.7297L23 24.3053L20.5757 26.7297C20.3956 26.9098 20.1594 27 19.9231 27C19.6869 27 19.4508 26.91 19.2706 26.7297C18.91 26.3692 18.91 25.7849 19.2706 25.424L21.6945 22.9999L19.2704 20.5757C18.9099 20.2152 18.9099 19.6308 19.2704 19.2702C19.6309 18.9099 20.215 18.9099 20.5756 19.2702L23 21.6944L25.4243 19.2702C25.7849 18.9099 26.3691 18.9099 26.7295 19.2702C27.0902 19.6308 27.0902 20.2152 26.7296 20.5757Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default { name: 'cusAttendanceAbsent' }
</script>

<style></style>
