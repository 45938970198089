<template>
  <v-dialog v-model="showModal" max-width="500" persistent>
    <v-card class="card-default">
      <v-card-title
        >Reminder: Today's
        {{ sessionsToShow.length > 1 ? 'Classes Have' : 'Class Has' }} Been
        Postponed</v-card-title
      >
      <v-card-text>
        <span>The following class sessions have been postponed:</span>
        <br />
        <ul v-for="ses in sessionsToShow">
          <li>
            <div>
              <b class="cls-txt">{{ ses.description }}</b>
              <span class="cls-txt" :title="formatExact(ses.startingAt)">
                &nbsp; {{ formatExact(ses.startingAt) }} w/ {{ ses.teacherName }}
              </span>
            </div>
          </li>
        </ul>
        <br />
        <span class="pt-2"
          >Please check the account email for additional details.</span
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <PrimaryButton @click="clearSessions">Got it!</PrimaryButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import useCancelledSessions from '@/utils/composables/useCancelledSessions'
import { formatExact } from '@/utils/dayjs'

const { beginChecking, showModal, sessionsToShow, clearSessions } =
  useCancelledSessions()
beginChecking()
</script>

<style lang="scss" scoped>
.cls-txt {
  display: block;
}
</style>
