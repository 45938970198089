<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M0.832653 9.16735C1.29388 9.16735 1.66531 9.53878 1.66531 10C1.66531 14.5959 5.40408 18.3347 10 18.3347C14.5959 18.3347 18.3347 14.5959 18.3347 10C18.3347 5.40408 14.5959 1.66531 10 1.66531C7.4898 1.66531 5.16327 2.77143 3.58776 4.67347H6.46531C6.92653 4.67347 7.29796 5.0449 7.29796 5.50612C7.29796 5.96735 6.92653 6.33878 6.46531 6.33878H1.78775C1.32653 6.33878 0.955103 5.96735 0.955103 5.50612V0.832653C0.955103 0.371429 1.32653 0 1.78775 0C2.24898 0 2.62041 0.371429 2.62041 0.832653V3.24082C4.50204 1.18776 7.15102 0 10 0C15.5143 0 20 4.48571 20 10C20 15.5143 15.5143 20 10 20C4.48571 20 0 15.5143 0 10C0 9.53878 0.371429 9.16735 0.832653 9.16735Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusRefresh',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
