<template>
  <section :class="noMargin ? 'no-margin' : ''">
    <v-progress-circular indeterminate :size="size ? size : '100'" color="primary" />
  </section>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: { type: String, default: '100' },
    noMargin: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px;
}

.no-margin {
  margin: 0;
}
</style>
