<script setup lang="ts">
import { axiosKey } from '@/utils/injectionKeys'
import { ref, inject, computed } from 'vue'
import { alert as $alert, error as $error } from '@/main'

const axios = inject(axiosKey)

const form = ref<InstanceType<typeof VForm>>(null)
const isValid = ref(false)
let isLoading = ref<Boolean>(false)
let isTimeForForm = ref<boolean>(false)

const studentFirstName = ref<string>('')
const studentLastName = ref<string>('')
const classDayAndTime = ref<string>('')
const instructorName = ref<string>('')
const parentEmail = ref<string>('')
const parentPhone = ref<string>('')
const issue = ref<string>('')

const required = [(v) => !!v || 'This field is required']

const emit = defineEmits(['form-sent'])

let isFormDisabled = computed(() => {
  checkForFormAvailability()
  return isLoading.value || !isTimeForForm.value
})

const checkForFormAvailability = () => {
  axios
    .get('/auth/emergency-form')
    .then((res) => {
      isTimeForForm.value = res.data.isFormAvailable
    })
    .catch((err) => {
      $error(`There was an error determining if the form is available. `)
    })
}

const handleSubmit = () => {
  if (form.value?.validate()) {
    axios
      .post('/auth/emergency-form', {
        studentFirstName: studentFirstName.value,
        studentLastName: studentLastName.value,
        classDayAndTime: classDayAndTime.value,
        instructorName: instructorName.value,
        parentEmail: parentEmail.value,
        parentPhone: parentPhone.value,
        issue: issue.value,
      })
      .then((res) => {
        $alert('Your form was submitted successfully. We will be in touch.')
        emit('form-sent')
      })
      .catch((err) => {
        $error(
          `There was an error submitting your form: ${err.response.data.detail} `
        )
      })
      .finally(() => (isLoading = false))
  }
}
</script>

<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="handleSubmit">
    <v-row>
      <v-col>
        <v-text-field
          label="Student First Name"
          v-model="studentFirstName"
          required
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Student Last Name"
          v-model="studentLastName"
          required
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Class Time and Day"
          v-model="classDayAndTime"
          required
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Instructor Name"
          v-model="instructorName"
          required
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Parent Email"
          v-model="parentEmail"
          required
          type="email"
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Parent Phone"
          v-model="parentPhone"
          required
          type="tel"
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-col>
      <v-textarea
        label="Issue"
        v-model="issue"
        required
        outlined
        :rules="required"
      ></v-textarea>
    </v-col>
    <v-row>
      <v-col>
        <p style="font-size: 15px">
          Emergency Class Support is available daily from
          <b>9 AM to 10 PM CT</b> when classes are in session, excluding holidays.
        </p>
      </v-col>
    </v-row>
    <v-col>
      <PrimaryButton
        :loading="isLoading"
        :disabled="isFormDisabled"
        type="submit"
        style="width: 100%; height: 48px"
        >Submit</PrimaryButton
      >
    </v-col>
  </v-form>
</template>

<style scoped></style>
