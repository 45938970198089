<template>
  <v-tooltip v-if="emoji.selectedBy.length > 0" bottom>
    <template v-slot:activator="{ on, attrs }">
      <article class="emoji" v-bind="attrs" v-on="on">
        {{ getEmoji(emoji.name) }} {{ emoji.selectedBy.length }}
      </article>
    </template>
    <span>{{ getTooltipText(emoji) }}</span>
  </v-tooltip>
</template>

<script setup lang="ts">
import { Emoji } from '../ActivityFeed/types'

type Props = { emoji: Emoji }
const props = defineProps<Props>()

function getEmoji(name: string) {
  if (name === 'heart') return '❤️'
  if (name === 'thumbUp') return '👍'
  if (name === 'laugh') return '😂'
  if (name === 'heartEyes') return '😍'
  if (name === 'sob') return '😭'
  if (name === 'angry') return '😡'
  if (name === 'skull') return '💀'
  if (name === 'thinking') return '🤔'
}

function getTooltipText(emoji: Emoji) {
  const names = emoji.selectedBy.map((e) => e.name)
  if (names.length > 3)
    return `${names[0]} and ${names.length - 1} others reacted with ${getEmoji(
      emoji.name
    )}`
  return `${names.join(', ')} reacted with ${getEmoji(emoji.name)}`
}
</script>

<style lang="scss" scoped>
.emoji {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  padding: 0;
  margin-top: 10px;
  min-width: 42px;
  max-width: 42px;
  min-height: 28px;
  border: 1px solid black;
  border-radius: 17px;
  margin-right: 2px;
  background-color: #eceef0 !important;
  border-color: #d8dbe0;
}
</style>
