export default [
  { text: 'Dashboard', screen: 'Dashboard' },
  { text: 'Editor', screen: 'Editor' },
  // { text: 'Homework', screen: 'Homework' },
  { text: 'Lessons', screen: 'Lessons' },
  // { text: 'Forum', url: 'https://forum.codewizardshq.com' },
  // {
  //   text: 'Documentation',
  //   url: 'http://docs.codewizardshq.com',
  // },
  // { text: 'Help', screen: 'Help' },
  {
    text: 'Help',
    nested: [
      { screen: 'Help', text: 'Help' },
      { text: 'Documentation', url: 'http://docs.codewizardshq.com' },
      { text: 'Forum', url: 'https://forum.codewizardshq.com' },
    ],
  },
] as NavigationTab[]

interface NavigationTab {
  text: string
  screen?: string
  url?: string
}
