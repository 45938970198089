import client from '@/utils/ajax/http'
import { useAccountStore } from '@/stores/accountStore'

export function bootIntercom(account) {
  if (!account.parent1Email) return

  Intercom('onShow', () => Intercom('hide'))

  const intercomSettings = {
    user_id: account.id,
    app_id: 'yajvqat6',
    email: account.parent1Email,
    name: `${account.studentFirstName} ${account.studentLastName} (${account.username})`,
  }

  try {
    Intercom('boot', intercomSettings)
  } catch (e) {
    console.error('error booting intercom!')
    console.error(e)
  }
}

export function getImgSrc(imgSrc: string) {
  return new URL(`../assets/${imgSrc}`, import.meta.url).href
  // console.log('img src HELPER is: ', imgSrc)
  // console.log('url HELPER is: ', url)
}

// certKey is AWS object key of the certificate
export async function downloadCert(certUrl, classId, username) {
  const blob = await fetch(certUrl).then((r) => r.blob())
  const dl = document.createElement('a')
  if (blob && blob.size) {
    dl.download = `CWHQ_Certificate_/${classId}/${username}.pdf`
    dl.href = window.URL.createObjectURL(blob)
    dl.click()
  } else {
    dl.href = certUrl
    dl.target = '_blank'
    dl.click()
  }
}

export function objToFormData(objToSend: Object | Blob, isFile = false) {
  const dataToSend = new FormData()

  Object.entries(objToSend).forEach(([key, value]) => {
    if (value === null) {
      dataToSend.append(key, '')
    } else {
      dataToSend.append(key, value)
    }
  })
  if (isFile) dataToSend.append('file', objToSend as Blob)
  return dataToSend
}

export function shuffle(array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function openForumURL() {
  const { selectedClassId } = useAccountStore()
  return new Promise((resolve, reject) => {
    client
      .get(`/editor/forum-url`, { params: { classId: selectedClassId } })
      .then((res) => {
        const url = res.data.forumUrl
        window.open(url)
        resolve('')
      })
      .catch(() => {
        reject()
      })
  })
}

export function mixpanel(event: string, parameters = {}) {
  return client.post('/user/mp', { event, parameters }).catch(() => {
    console.error('Server-side mixpanel event failed to send')
  })
}

export function refreshPreview(isShowingPreviewPanel) {
  // console.log('inside refreshPreview')
  if (!isShowingPreviewPanel) return
  // adds a bogus ts= query string parameter to make sure the browser
  // doesn't attempt to use a cached copy of the page. the value could
  // really be anything as long as its always different.
  const iframe = document.getElementById('preview-frame') as HTMLIFrameElement

  if (iframe.src.includes('ts'))
    iframe.src = iframe.src.replace(/ts=[^&]+/, `ts=${Date.now()}`)
  else iframe.src += `${iframe.src.includes('?') ? '&' : '?'}ts=${Date.now()}`
}

export function getAwardSrc(typeOfAward, score, total) {
  let bronze = 1
  let silver = 3
  let gold = 6

  if (total === 2) {
    bronze = 1
    silver = 2
    gold = 3
  }
  if (total === 5) {
    bronze = 1
    silver = 3
    gold = 5
  }

  if (typeOfAward === 'QuizDetail') {
    if (score >= gold) return 'gold.webp'
    if (score >= silver) return 'silver.webp'
    return 'bronze.webp'
  }

  if (typeOfAward === 'b') {
    if (score >= bronze) return 'bronze.webp'
    else return 'bronze-empty.webp'
  }

  if (typeOfAward === 's') {
    if (score >= silver) return 'silver.webp'
    else return 'silver-empty.webp'
  }

  if (score >= gold) return 'gold.webp'
  else return 'gold-empty.webp'
}

/*export function initAxios(store) {
  axios.interceptors.request.use((request) => {
    if (store.state.account.username) {
      request.headers['X-Editor-User'] = store.state.account.username
    }
    return request
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const is401 = error.response.status === 401
      const isLogin = error.config.url === '/api/login'

      if (is401 && !isLogin) {
        store.commit(SET_ACCOUNT_EMPTY)
      }

      return Promise.reject(error)
    }
  )
}*/

export function isCodeFile(file) {
  if (!file) return false
  if (typeof file !== 'string') return false
  const fileType = file.split('.').pop()
  const fileTypes = [
    'html',
    'css',
    'js',
    'java',
    'py',
    'txt',
    'jinja2',
    'json',
    'csv',
  ]
  return fileTypes.includes(fileType)
}
