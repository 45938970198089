<template>
  <v-tooltip :disabled="!showTooltip" bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <PrimaryButton
          :elevation="0"
          :disabled="showTooltip"
          :loading="isLoading.zoomMeeting"
          class="hidden-xs-only mr-2"
          @click.prevent="$emit('go-to-meeting')"
        >
          <span>Join Class</span>
        </PrimaryButton>
      </div>
    </template>
    <span v-show="showTooltip">{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script setup lang="ts">
import { useAccountStore } from '@/stores/accountStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

type Props = { isLoading: any }
defineProps<Props>()
type Emits = { (e: 'go-to-meeting'): void }
defineEmits<Emits>()

const { enrolledStatus } = storeToRefs(useAccountStore())
const showTooltip = computed(() => tooltipText.value.length > 0)
const tooltipText = computed(() => {
  const status = enrolledStatus.value
  if (status === 'ACTIVE') return ''
  if (status === 'PLANNED') return "You don't have any active classes right now"
  return "You're not enrolled in any classes"
})
</script>
