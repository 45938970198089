<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill-opacity="0.01" />
    <path
      d="M7.81338 14.3921C7.98047 14.5568 8.20599 14.6497 8.44132 14.6506C8.56 14.6502 8.67741 14.6263 8.78674 14.5803C8.89607 14.5344 8.99514 14.4674 9.07822 14.3832C9.2453 14.2161 9.33908 13.9902 9.33908 13.7546C9.33908 13.5191 9.2453 13.2931 9.07822 13.1261L6.07311 10.1928H12.0295C13.9328 10.1928 15.7582 10.9443 17.104 12.2819C18.4498 13.6195 19.2059 15.4337 19.2059 17.3253V19.1084C19.2059 19.3449 19.3004 19.5717 19.4686 19.7389C19.6369 19.9061 19.865 20 20.103 20C20.3409 20 20.569 19.9061 20.7373 19.7389C20.9055 19.5717 21 19.3449 21 19.1084V17.3253C21 14.9608 20.0549 12.693 18.3726 11.021C16.6903 9.34904 14.4086 8.40972 12.0295 8.40972H6.09105L9.06925 5.47649C9.21751 5.30698 9.29594 5.08811 9.28886 4.86361C9.28177 4.63912 9.1897 4.42556 9.03104 4.26562C8.87238 4.10568 8.65883 4.01115 8.43308 4.00092C8.20733 3.9907 7.98602 4.06554 7.81338 4.21047L3.26535 8.66827C3.18127 8.75116 3.11453 8.84976 3.06899 8.95841C3.02345 9.06705 3 9.18358 3 9.30128C3 9.41898 3.02345 9.53551 3.06899 9.64415C3.11453 9.7528 3.18127 9.85141 3.26535 9.93429L7.81338 14.3921Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusUndo',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
