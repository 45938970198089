import client from './http'
import { Notification } from '@/stores/notificationsStore'

export default {
  getNotifications() {
    return client.get<Notification[]>('/notifications/tray')
  },

  updateSingleNotificationStatus(id: number) {
    return client.post<undefined>(`/notifications/dismiss/${id}`)
  },
  getNotificationCount() {
    return client.get<{ count: number; now: string }>('/notifications/count')
  },
}
