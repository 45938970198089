<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="6.10352e-05" width="20" height="20" fill="white" fill-opacity="0.01" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.41025 14.9275C9.56653 15.0838 9.77849 15.1716 9.9995 15.1716C10.2205 15.1716 10.4325 15.0838 10.5888 14.9275C10.745 14.7712 10.8328 14.5593 10.8328 14.3382V4.51325L13.5745 7.26325C13.6522 7.34095 13.7444 7.40258 13.846 7.44463C13.9475 7.48668 14.0563 7.50832 14.1662 7.50832C14.2761 7.50832 14.3849 7.48668 14.4864 7.44463C14.5879 7.40258 14.6801 7.34095 14.7578 7.26325C14.8355 7.18555 14.8972 7.09331 14.9392 6.99179C14.9813 6.89027 15.0029 6.78146 15.0029 6.67158C15.0029 6.5617 14.9813 6.45289 14.9392 6.35137C14.8972 6.24985 14.8355 6.15761 14.7578 6.07991L10.5912 1.91325C10.5137 1.83514 10.4215 1.77314 10.32 1.73084C10.2184 1.68853 10.1095 1.66675 9.9995 1.66675C9.88949 1.66675 9.78057 1.68853 9.67902 1.73084C9.57747 1.77314 9.48531 1.83514 9.40784 1.91325L5.24117 6.07991C5.08425 6.23683 4.99609 6.44966 4.99609 6.67158C4.99609 6.8935 5.08425 7.10633 5.24117 7.26325C5.39809 7.42017 5.61092 7.50832 5.83284 7.50832C6.05475 7.50832 6.26758 7.42017 6.4245 7.26325L9.16617 4.51325V14.3382C9.16617 14.5593 9.25397 14.7712 9.41025 14.9275ZM2.49935 17.8334H17.4993C17.7204 17.8334 17.9323 17.9212 18.0886 18.0774C18.2449 18.2337 18.3327 18.4457 18.3327 18.6667C18.3327 18.8877 18.2449 19.0997 18.0886 19.256C17.9323 19.4122 17.7204 19.5 17.4993 19.5H2.49935C2.27834 19.5 2.06637 19.4122 1.91009 19.256C1.75381 19.0997 1.66602 18.8877 1.66602 18.6667C1.66602 18.4457 1.75381 18.2337 1.91009 18.0774C2.06637 17.9212 2.27834 17.8334 2.49935 17.8334Z"
      fill="#3D4A65"
    />
  </svg>
</template>

<script>
export default { name: 'cusUploadFile' }
</script>

<style></style>
