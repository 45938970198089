<template>
  <v-tabs
    v-model="currentTab"
    class="tabs hidden-md-and-down"
    show-arrows
    hide-slider
  >
    <v-spacer />
    <template v-for="(tab, index) in tabs">
      <!-- NESTED -->
      <v-menu v-if="tab.nested" :key="index" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-bind="attrs"
            class="text-decoration-none text-capitalize nav-tab adjust-margin nav-style"
            v-on="on"
            @click="mixpanelTopLevel(tab.text)"
          >
            <span class="nav-style hack">{{ tab.text }}</span>
            <v-icon medium>mdi-menu-down</v-icon>
          </v-tab>
        </template>
        <v-list>
          <template v-for="(n, i) in tab.nested">
            <v-list-item
              v-if="n.screen && !disableOfficeHoursTab(n.text)"
              :key="i"
              id="nav-title"
              :to="{ name: n.screen }"
              class="text-decoration-none nav-style"
              @click="mixpanelMenuSections(n.text, tab.text)"
            >
              <v-list-item-title class="font-weight-moderate nav-style">
                {{ n.text }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="n.url"
              :key="n.s"
              :href="n.url"
              class="text-decoration-none nav-style"
              target="_blank"
              @click="mixpanelMenuSections(n.text, tab.text)"
            >
              <v-list-item-title class="font-weight-moderate nav-style">
                {{ n.text }}
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="i < tab.nested.length - 1"
              :key="i + 'tab'"
              class="my-0"
            />
          </template>
        </v-list>
      </v-menu>
      <!-- URL -->
      <template v-else-if="tab.url">
        <v-tab
          :key="tab.url"
          :href="tab.url"
          class="text-decoration-none nav-tab adjust-margin nav-style font-weight-500"
          target="_blank"
          @click="mixpanelTopLevel(tab.text)"
        >
          {{ tab.text }}
        </v-tab>
      </template>
      <!-- SCREEN -->
      <v-tab
        v-else
        :key="tab.screen"
        :to="{ name: tab.screen }"
        @click="mixpanelTopLevel(tab.text)"
        class="text-decoration-none light-text text-capitalize nav-tab adjust-margin nav-style"
      >
        <template>{{ tab.text }}</template>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import tabs from '@/components/NavBar/tabs.ts'
import { useAccountStore } from '@/stores/accountStore'
import { mapState } from 'pinia'
export default {
  name: 'NavTabs',
  data() {
    return { tabs, currentTab: '' }
  },
  computed: { ...mapState(useAccountStore, ['selectedClass']) },
  methods: {
    tabHighlight(currRouteObj) {
      if (['/homework', '/quiz', '/report_card'].includes(currRouteObj.path)) {
        this.currentTab = 2
      } else if (['/files'].includes(currRouteObj.path)) {
        this.currentTab = 3
      } else if (
        ['/office_hours', '/help_videos', '/homework_help'].includes(
          currRouteObj.path
        )
      ) {
        this.currentTab = 5
      }
    },
    mixpanelMenuSections(tabName, menuName) {
      this.$mixpanel('Click Menu Sections', {
        section: tabName,
        parentSection: menuName,
        type: 'Desktop Width',
      })
    },
    mixpanelTopLevel(tabName) {
      this.$mixpanel('Top Level Navigation', {
        section: tabName,
        type: 'Desktop Width',
      })

      if (tabName === 'Documentation') {
        this.$mixpanel('Navigate to Documentation', { fromLocation: 'Navigation' })
      }
    },
    // deprecated
    disableOfficeHoursTab(tabName) {
      if (tabName === 'Office Hours') {
        if (this.selectedClass.disableOfficeHours || !this.selectedClass.id) {
          return true
        }
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
/* this was removed from edit.css and moved here */
.tabs::v-deep .v-tabs-slider-wrapper {
  bottom: 16px !important;
}

.tabs::v-deep .v-tab--active {
  color: white;
}

.v-tab.nav-tab {
  margin-right: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  text-transform: capitalize;
}

.hack.nav-style,
.nav-style {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--v-secondary-base) !important;
}

.nav-style.v-tab--active {
  font-weight: 700 !important;
  color: var(--v-text-base) !important;
}

/* .adjust-margin {
  margin-left: 32px;
}

@media screen and (max-width: 1444px) {
  .adjust-margin {
    margin-left: 16px;
  }
} */
</style>
