import { useNotificationsStore } from './notificationsStore'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { bootIntercom } from '@/utils/helperFunctions'
import client from '@/utils/ajax/http'
import * as Sentry from '@sentry/vue'

export type Account = {
  id: number
  avatar: string
  parent1email: string
  roles: string[]
  studentFirstName: string
  studentLastName: string
  teach: boolean
  admin: boolean
  username: string
  status: 'Active' | string
  trial: boolean
  backendOffline: false
}
type CurrentClass = {
  channel: number
  id: number
  name: string
  course: Course
  disableFeedback: boolean
  disableHomework: boolean
  disableOfficeHours: boolean
  disableQuiz: boolean
  teacher: { id: number }
  week: number
  trial: boolean
}
type Course = {
  customerDisplay: string
  id: number
  name: string
  forumCategory: string
  appointmentType: number
  hhEligible: boolean
}

/**The account store is for anything related to the user or that is needed for the basic app to function. */
export const useAccountStore = defineStore('account', {
  state: () => ({
    refreshKey: 0,
    account: {} as Account,
    /**Deprecated, and will be removed */
    // currentClass: {} as CurrentClass,
    /**An array of class objects the user is currently enrolled in */
    currentClasses: [] as CurrentClass[],
    /**An array of planned class objects the user is currently enrolled in */
    plannedClasses: [] as CurrentClass[],
    /**The id of the class selected from the currentClasses array */
    selectedClassId: null as number,
    appNeedsRefresh: false,
    nextClassStartDate: null,
    impersonate: false,
    backendOffline: false,
    hideNavigation: false,
    alert: {
      active: false,
      message: '',
      timeout: 3000,
      multiline: false,
      color: 'success',
    },
  }),
  actions: {
    setAccountEmpty() {
      useNotificationsStore().endNotificationsInterval()
      this.account = null
      this.selectedClassId = null
    },
    setAlert({ message, color, multiline, timeout }) {
      this.alert = {
        active: true,
        message,
        color: color || 'success',
        multiline: multiline || false,
        timeout: timeout || 3000,
      }
    },
    beginImpersonate(username) {
      if (!this.userRolesIncludesOneOf(['full', 'impersonate'])) {
        alert('You do not have authorization to impersonate')
        return
      }

      client
        .get(`/auth/impersonate/${username}`)
        .then(() => {
          // @ts-ignore
          window.location.reload(true)
        })
        .catch((err) => {
          console.log('impersonate error', err)
          if (err.response && err.response.data.detail) {
            alert(err.response.data.detail)
          } else {
            alert('There was an error impersonating ' + username)
          }
        })
    },

    endImpersonate() {
      if (!this.impersonate) {
        alert('You are not currently impersonating')
        return
      }

      client.get('/auth/impersonate/stop').then(() => {
        const origin = window.location.origin
        window.location.assign(origin + '/dashboard')
      })
    },

    logout() {
      return new Promise((resolve, reject) => {
        client
          .delete('/auth/logout/learn')
          .then(() => {
            this.setAccountEmpty()
            Sentry.setUser(null)
            resolve('Success')
          })
          .catch((err) => {
            alert('There was an error logging out.')
            console.warn('Error logging out', err)
            reject(err)
          })
      })
    },

    getAccount() {
      return new Promise((resolve, reject) => {
        client
          .get('/user/account')
          .then((res) => {
            this.currentClasses = res.data.currentClasses
            this.plannedClasses = res.data.plannedClasses
            this.hideNavigation = res.data.hideNavigation

            if (res.data.currentClasses.length > 0) {
              this.selectedClassId = res.data.currentClasses[0].id
            }

            // if (res.data.currentClass) {
            //   // this.currentClass = res.data.currentClass
            //   // TODO: for now we are just putting the current class in the array
            //   this.currentClasses = [res.data.currentClass]

            //   // adding a second class to test having a dropdown
            //   //@ts-ignore
            //   // this.currentClasses.push({ className: 'Test Class', classId: 0 })
            //   this.selectedClassId = res.data.currentClass.classId
            // }
            this.nextClassStartDate = res.data.nextClassStartDate
            this.account = res.data.account

            const params = new URLSearchParams(window.location.search)
            const impersonate = params.get('impersonate')
            if (impersonate && res.data.account.admin) {
              console.log('impersonating: ', impersonate)
              this.beginImpersonate(impersonate)
              return
            }

            this.impersonate = res.data.impersonating

            bootIntercom(this.account)
            Sentry.setUser({
              email: this.account.parent1email,
              username: this.account.username,
              id: this.account.id as unknown as string,
            })
            resolve('Success')
          })
          .catch((err) => {
            console.log('Error loading account: ', err)
            reject('Error loading account' + err)
            if (err.response && err.response.status < 500) {
              return
            }
            console.log('backend is offline')
            this.backendOffline = true
          })
      })
    },

    updateClassData() {
      return new Promise((resolve, reject) => {
        // no longer setting this to hide the between-route loader
        // commit('SET_IS_LOADING_ROUTE', true)
        client
          .get('/user/class')
          .then((res) => {
            this.currentClasses = res.data
            resolve('Success')
          })
          .catch((err) => {
            console.error('Error fetching current class: ', err)
            reject()
          })
      })
    },
  },
  getters: {
    userRolesIncludesOneOf: (state) => (rolesArray: string[]) => {
      if (!state.account.roles) return false
      for (let role of rolesArray) {
        if (state.account.roles.includes(role)) {
          return true
        }
      }
      return false
    },
    selectedClass(state): CurrentClass | null {
      if (state.selectedClassId) {
        const cls = state.currentClasses.find(
          (cl) => cl.id === state.selectedClassId
        )
        if (cls) return cls
      }
      return null
    },
    enrolledStatus(state) {
      if (state.currentClasses.length >= 1) return 'ACTIVE'
      if (state.plannedClasses.length >= 1) return 'PLANNED'
      return 'NO_CLASSES'
    },
  },
})

export type PiniaStore<T extends (...args: any) => any> = Omit<
  ReturnType<T>,
  keyof ReturnType<typeof defineStore>
>

export type AccountStore = PiniaStore<typeof useAccountStore>

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
}
