<template>
  <v-menu offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <section class="wrapper" v-bind="attrs" v-on="on">
        <v-progress-circular v-if="isLoading" indeterminate />
        <template v-else>
          <div class="img-wrapper">
            <img :src="checkSrc" />
            <img v-if="correctlyAnswered" :src="iconSrc" class="check" />
          </div>

          <ChallengeTimer v-if="showTimer" v-bind="attributes" class="ml-2" />
        </template>
      </section>
    </template>
    <WeeklyChallengeCard location="Top Nav" />
  </v-menu>
</template>

<script setup lang="ts">
import { useChallengeStore } from '@/stores/challengeStore'
import ChallengeTimer from '../Challenges/Timer/ChallengeTimer.vue'
import { getImgSrc } from '@/utils/helperFunctions'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

type Props = { hideSec?: boolean }
const props = defineProps<Props>()

const store = useChallengeStore()
const { isLoading, activeQuestion } = storeToRefs(store)

const attributes = {
  hideSec: props.hideSec,
  color: '#FFEFCD',
}

const correctlyAnswered = computed(() => activeQuestion.value?.isCorrect)
const showTimer = computed(() => activeQuestion.value?.showTimer)
const checkSrc = getImgSrc(`images/challenges/lightbulb.png`)
const iconSrc = getImgSrc('icons/green-check-filled-circle.png')
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  position: relative;

  .img-wrapper {
    display: flex;
    align-items: center;
  }

  .check {
    max-height: 16px;
    position: absolute;
    top: -5px;
    right: 0px;
  }
}
</style>
