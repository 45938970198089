import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import { useAccountStore } from '@/stores/accountStore'

const AvatarEdit = () => import('@/components/Avatar/AvatarEdit.vue')
const Achievements = () => import('@/components/Achievements/Achievements.vue')
const ActivityFeed = () => import('@/components/ActivityFeed/ActivityFeed.vue')
const Certificates = () => import('@/components/Certificates/Certificates.vue')
const Challenges = () => import('@/components/Challenges/Challenges.vue')
const ChalTest = () => import('@/components/Challenges/ChallengeTest.vue')
const StudentSupport = () => import('@/components/StudentSupport/StudentSupport.vue')
const Lessons = () => import('@/components/Lesson/Lessons.vue')
const Dashboard = () => import('@/components/Dashboard/Dashboard.vue')
const Editor = () => import('@/components/Editor/Editor.vue')
const Quiz = () => import('@/components/Quiz/Quiz.vue')
const HelpVideos = () => import('@/components/HelpVideos/HelpVideos.vue')
const HelpVideo = () => import('@/components/HelpVideos/HelpVideo.vue')
const HomeworkHelp = () => import('@/components/HomeworkHelp/HomeworkHelp.vue')
const OfficeHours = () => import('@/components/OfficeHours.vue')
const Placement = () => import('@/components/Placement/Placement.vue')
const PlacementTest = () => import('@/components/Placement/PlacementTest.vue')
const Referral = () => import('@/components/Referral/Referral.vue')
const Feedback = () => import('@/components/StudentFeedback/Feedback.vue')

const portalRedirect = async (to: Route, from: Route, next: NavigationGuardNext) => {
  window.open('https://parents.codewizardshq.com/')
  return next('/dashboard')
}

const routes = [
  { path: '/referral', component: Referral, name: 'Referral' },
  { path: '/class_feed', component: ActivityFeed, name: 'Class Feed' },
  { path: '/avatar', component: AvatarEdit, name: 'Avatar Edit' },
  { path: '/achievements', component: Achievements, name: 'Achievements' },
  { path: '/challenges', component: Challenges, name: 'Challenges' },
  { path: '/challenge_test', component: ChalTest, name: 'Challenge Test' },
  { path: '/dashboard', component: Dashboard, name: 'Dashboard' },
  { path: '/editor', component: Editor, name: 'Editor', meta: { title: 'Code' } },
  { path: '/quiz/:classId/:lessonId', component: Quiz, name: 'Quiz' },
  { path: '/lessons', component: Lessons, name: 'Lessons' },
  { path: '/certificates', component: Certificates, name: 'Certificates' },
  { path: '/help_videos', component: HelpVideos, name: 'Help Videos' },
  { path: '/help_videos/:id', component: HelpVideo, name: 'Help Video' },
  { path: '/homework_help', component: HomeworkHelp, name: 'Homework Help' },
  { path: '/help', component: StudentSupport, name: 'Help' },
  { path: '/office_hours', component: OfficeHours, name: 'Office Hours' },
  { path: '/placement', component: Placement, name: 'Placement' },
  { path: '/placement/:testId', component: PlacementTest, name: 'Placement Test' },
  { path: '/welcome', name: 'Welcome', beforeEnter: portalRedirect },
  { path: '/welcome/video/:src', beforeEnter: portalRedirect },
  { path: '/welcome/slides/:src', beforeEnter: portalRedirect },
  { path: '/welcome/survey', name: 'Welcome Survey', beforeEnter: portalRedirect },
  { path: '/feedback', component: Feedback, name: 'Feedback' },
  { path: '*', component: Dashboard, name: 'Dashboard' },
]

const router = new VueRouter({ mode: 'history', base: '/', routes })

router.beforeEach(async (to, from, next) => {
  // const accountStore = useAccountStore(pinia)
  const accountStore = useAccountStore()

  if (!accountStore.account.id) {
    document.title = 'Student Editor Login | CodeWizardsHQ'
  } else if (!!to.name) document.title = `${to.name} | CodeWizardsHQ`

  await accountStore.updateClassData()
  next()
})

export default router
