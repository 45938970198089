<template>
  <section
    class="page-layout"
    :style="`max-width: ${width};`"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'PageLayout',
  props: { maxWidth: { default: 1200, type: Number } },
  computed: {
    width() {
      return `${this.maxWidth}px`
    },
  },
}
</script>

<style>
.page-layout {
  margin: 0 auto;
}
</style>
