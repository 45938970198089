import type { AccountStore } from '@/stores/accountStore'
import type { AxiosInstance } from 'axios'

const LOGIN_URL = '/learn/api/auth/login/learn'

function logoutInterceptor(instance: AxiosInstance, store: AccountStore) {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // non-logout 401 error - propagate up to error handler, or bad username/password
      if (
        error.response.status !== 401 ||
        (error.response.status === 401 && error.config.url === LOGIN_URL)
      ) {
        return Promise.reject(error)
      }

      // 401 anywhere except the login url
      store.setAccountEmpty()
      return Promise.reject(error)
    }
  )
}

function gitVersionInterceptor(instance: AxiosInstance, store: AccountStore) {
  instance.interceptors.response.use((response) => {
    if (
      response.headers['x-git-version'] &&
      import.meta.env.VITE_GIT_VERSION &&
      response.headers['x-git-version'] !== import.meta.env.VITE_GIT_VERSION
    ) {
      store.appNeedsRefresh = true
    }
    return response
  })
}

export function initAxiosInterceptors(i: AxiosInstance, s: AccountStore) {
  logoutInterceptor(i, s)
  gitVersionInterceptor(i, s)
}
