<template>
  <v-dialog v-model="joinClassModal" persistent max-width="max-content">
    <v-card class="card-default">
      <v-card-title>
        <span>Which class would you like to join?</span>
        <v-spacer />
        <v-icon @click="emit('close')">mdi-close</v-icon></v-card-title
      >
      <div class="entry-wrapper">
        <p v-if="zoomClasses.length < 1">No class sessions found.</p>

        <!-- entry -->
        <template v-for="(ses, i) in zoomClasses">
          <div class="class-entry">
            <PrimaryButton
              v-if="!ses.canceled"
              :href="ses.zoomLink"
              @click="emit('close')"
              target="_blank"
              >Join Class</PrimaryButton
            >
            <v-btn
              v-else
              :href="ses.zoomLink"
              @click="emit('close')"
              target="_blank"
              color="red"
              >Postponed</v-btn
            >
            <div class="cls-txt">
              <div class="d-flex align-center">
                <h3>{{ ses.description }}</h3>
                <v-btn text x-small class="ml-2" @click="copyLink(ses.zoomLink)">
                  <v-icon x-small>mdi-clipboard</v-icon>
                  <span>Copy Link</span>
                </v-btn>
              </div>
              <div>
                <span
                  >{{ formatExact(ses.startingAt) }} w/ {{ ses.teacherName }}</span
                >
              </div>
            </div>
          </div>
          <v-divider v-if="i < zoomClasses.length - 1" class="my-1" />
        </template>
        <br />
        <div v-if="somePostponed" class="explainer">
          <div>
            <b>Reminder:</b> At least one of your classes today has been postponed.
          </div>
          <div>
            For full details, please see the email sent to the primary account
            holder.
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { alert } from '@/main'
import copy from 'copy-to-clipboard'
import { formatExact } from '@/utils/dayjs'
import { computed } from 'vue'

type Props = { zoomClasses: ZoomClass[]; joinClassModal: boolean }
const props = defineProps<Props>()

type Emits = { (e: 'close'): void }
const emit = defineEmits<Emits>()

function copyLink(url: string) {
  const copiedSuccessfully = copy(url)

  if (copiedSuccessfully) {
    alert('URL copy successful')
  }
}

const somePostponed = computed(() => {
  for (const ses of props.zoomClasses) {
    if (ses.canceled) {
      return true
    }
  }
})
</script>

<style scoped lang="scss">
.entry-wrapper {
  padding: 0 16px;
  padding-bottom: 16px;
}

.class-entry {
  display: flex;
  align-items: center;

  .cls-txt {
    margin-left: 8px;
  }
}

.explainer {
  font-size: 14px;
}
</style>
