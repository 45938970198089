<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.043 2.95703C15.1562 1.07035 12.6602 0 10 0C4.50559 0 0 4.49672 0 10C0 15.5062 4.49512 20 10 20C15.4807 20 20 15.5239 20 10C20 7.3398 18.9297 4.84371 17.043 2.95703ZM10 17.6172C5.78961 17.6172 2.38281 14.2193 2.38281 10C2.38281 8.35934 2.89844 6.80074 3.84766 5.51168L14.4883 16.1406C13.1992 17.1016 11.6407 17.6172 10 17.6172ZM16.1406 14.4882L5.51168 3.84766C6.80082 2.89844 8.35934 2.38281 10 2.38281C14.2105 2.38281 17.6172 5.78066 17.6172 10C17.6172 11.6406 17.1016 13.1992 16.1406 14.4882Z"
      fill="#D8DBE0"
    />
  </svg>
</template>

<script>
export default { name: 'cusClosedCircle' }
</script>

<style></style>
