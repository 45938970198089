<template>
  <v-card v-if="appNeedsRefresh && showBanner" class="refresh-card">
    <span class="mr-2">
      There is a CodeWizardsHQ update that hasn't taken effect for you yet. Please
      refresh the page when you have a moment.
    </span>
    <v-btn @click="showBanner = false" outlined>Remind Me Later</v-btn>
  </v-card>
</template>

<script>
import { useAccountStore } from '@/stores/accountStore'
import { mapState } from 'pinia'

export default {
  name: 'RefreshPageModal',
  data() {
    return { showBanner: true }
  },
  computed: { ...mapState(useAccountStore, ['appNeedsRefresh']) },
}
</script>

<style lang="scss" scoped>
.refresh-card {
  position: fixed;
  bottom: 0px;
  min-height: 80px;
  width: 100vw;
  border-radius: 0px;
  z-index: 100;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
