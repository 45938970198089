<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <rect width="24" height="24" fill-opacity="0.01" />
    <path
      d="M16.1866 14.3921C16.0195 14.5568 15.794 14.6497 15.5587 14.6506C15.44 14.6502 15.3226 14.6263 15.2133 14.5803C15.1039 14.5344 15.0049 14.4674 14.9218 14.3832C14.7547 14.2161 14.6609 13.9902 14.6609 13.7546C14.6609 13.5191 14.7547 13.2931 14.9218 13.1261L17.9269 10.1928H11.9705C10.0672 10.1928 8.24185 10.9443 6.89601 12.2819C5.55018 13.6195 4.7941 15.4337 4.7941 17.3253V19.1084C4.7941 19.3449 4.69959 19.5717 4.53136 19.7389C4.36313 19.9061 4.13496 20 3.89705 20C3.65914 20 3.43097 19.9061 3.26274 19.7389C3.09451 19.5717 3 19.3449 3 19.1084V17.3253C3 14.9608 3.9451 12.693 5.62739 11.021C7.30969 9.34904 9.59137 8.40972 11.9705 8.40972H17.909L14.9307 5.47649C14.7825 5.30698 14.7041 5.08811 14.7111 4.86361C14.7182 4.63912 14.8103 4.42556 14.969 4.26562C15.1276 4.10568 15.3412 4.01115 15.5669 4.00092C15.7927 3.9907 16.014 4.06554 16.1866 4.21047L20.7347 8.66827C20.8187 8.75116 20.8855 8.84976 20.931 8.95841C20.9766 9.06705 21 9.18358 21 9.30128C21 9.41898 20.9766 9.53551 20.931 9.64415C20.8855 9.7528 20.8187 9.85141 20.7347 9.93429L16.1866 14.3921Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusRedo',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
