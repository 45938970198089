<template>
  <div class="box" :class="fullWidth ? 'full-width' : ''">
    <span class="display"
      >{{ pad(display) }}
      <div class="label">{{ label }}</div>
    </span>
  </div>
</template>

<script setup lang="ts">
type Props = { label: string; display: number; color: string; fullWidth: boolean }
const props = defineProps<Props>()
const pad = (x: number) => x.toString().padStart(2, '0')
</script>

<style lang="scss" scoped>
.box {
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #ebedef;
  background-color: v-bind('color');
  min-width: 34px;
  min-height: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #223239;

  font-size: 18px;
  font-weight: 700;
  line-height: 140%; /* 25.2px */

  .display {
    margin-top: 2px;
    position: relative;

    .label {
      position: absolute;
      top: -4px;
      left: 0px;
      width: 100%;
      color: var(--additional-dark-blue, #3d4a65);

      font-size: 6.667px;

      font-weight: 800;
      line-height: 8.333px; /* 125% */
      text-transform: uppercase;
    }
  }
}
</style>
