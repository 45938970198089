<template>
  <svg
    width="38"
    height="40"
    viewBox="0 0 38 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12H37V30C37 35.5228 32.5228 40 27 40H11C5.47715 40 1 35.5228 1 30V12Z"
      fill="#F4F4F5"
    />
    <path
      d="M29.9938 2.70438V1.81442C29.9938 0.814074 29.1815 0 28.1833 0C27.1851 0 26.3728 0.814074 26.3728 1.81442V2.5733H20.8105V1.81442C20.8105 0.814074 19.9982 0 19 0C18.0018 0 17.1895 0.814074 17.1895 1.81442V2.5733H11.6272V1.81442C11.6272 0.814074 10.8149 0 9.81667 0C8.81848 0 8.00616 0.814074 8.00616 1.81442V2.70438C3.40073 3.42877 0 7.30597 0 11.9696V30.6037C0 35.7848 4.28188 40 9.53442 40H28.4656C33.7181 40 38 35.7848 38 30.6037V11.9696C38 7.30597 34.5993 3.42877 29.9938 2.70438ZM27.7496 1.81442C27.7496 1.57296 27.9424 1.37979 28.1833 1.37979C28.425 1.37979 28.617 1.57296 28.617 1.81442V4.66368C28.617 4.89824 28.425 5.09831 28.1833 5.09831C27.9424 5.09831 27.7496 4.89824 27.7496 4.66368V1.81442ZM18.5663 1.81442C18.5663 1.57296 18.7591 1.37979 19 1.37979C19.2409 1.37979 19.4337 1.57296 19.4337 1.81442V4.66368C19.4337 4.89824 19.2409 5.09831 19 5.09831C18.7591 5.09831 18.5663 4.89824 18.5663 4.66368V1.81442ZM9.38297 1.81442C9.38297 1.57296 9.57504 1.37979 9.81667 1.37979C10.0576 1.37979 10.2504 1.57296 10.2504 1.81442V4.66368C10.2504 4.89824 10.0576 5.09831 9.81667 5.09831C9.57504 5.09831 9.38297 4.89824 9.38297 4.66368V1.81442ZM36.6232 30.6037C36.6232 35.019 32.9616 38.6202 28.4656 38.6202H9.53442C5.03913 38.6202 1.37681 35.019 1.37681 30.6037V13.1839H36.6232V30.6037Z"
      fill="#D8DBE0"
    />
  </svg>
</template>

<script>
export default { name: 'cusCalendar' }
</script>

<style></style>
