<template>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4368 1.44151L12.4363 1.44049C12.1883 0.868602 11.6242 0.5 11.0001 0.5C10.3758 0.5 9.81155 0.868814 9.56368 1.44179L9.56342 1.44239L7.39865 6.4644L1.92169 6.95732L1.92088 6.95739C1.29994 7.0143 0.77164 7.43139 0.577242 8.02403C0.382781 8.61687 0.562596 9.26742 1.03543 9.67685C1.03564 9.67704 1.03585 9.67722 1.03606 9.6774L5.17221 13.2733L3.95248 18.5996L3.95237 18.6001C3.81342 19.2098 4.05281 19.8407 4.562 20.2055L4.5621 20.2056C4.83355 20.4 5.15297 20.5 5.47581 20.5C5.75463 20.5 6.03028 20.4253 6.2758 20.2797L11 17.4807L15.7215 20.2791C15.7216 20.2791 15.7217 20.2792 15.7218 20.2793C16.2573 20.5985 16.9315 20.569 17.4377 20.2052C17.9464 19.8398 18.186 19.2091 18.0468 18.5999L18.0468 18.5996L16.827 13.2734L20.9637 9.67772C21.4355 9.26849 21.6176 8.61939 21.4232 8.02485C21.2297 7.43153 20.6999 7.01305 20.0776 6.95732C20.0775 6.95732 20.0775 6.95731 20.0775 6.95731C20.0774 6.9573 20.0773 6.9573 20.0772 6.95729L14.6015 6.46441L12.4368 1.44151Z"
      fill="url(#paint0_linear_1646_26150)"
      stroke="#DEAC1E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1646_26150"
        x1="11"
        y1="1"
        x2="11"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFDE4B" />
        <stop offset="1" stop-color="#FFC844" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'cusStarFull',
}
</script>

<style scoped>
.svg {
  fill: currentColor;
}
</style>
