<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card class="text-center pt-10 pb-10">
      <img
        v-if="$vuetify.theme.dark"
        class="error-cross"
        src="/src/assets/illustrations/error-dark.svg"
      />
      <img
        v-else
        class="error-cross"
        src="/src/assets/illustrations/error-light.svg"
      />
      <v-form @submit.prevent="dismiss">
        <v-card-title class="justify-center">Unsupported Browser</v-card-title>
        <v-card-text>
          <p class="warning--text">
            <strong
              >Some features of the CodeWizardsHQ ecosystem do not work in this
              browser.</strong
            >
          </p>
          <p>
            For an optimal student experience, we <i>strongly</i> recommend that you
            download and switch to either a Chromium-based browser such as
            <a
              class="primary--text"
              href="https://www.google.com/chrome/"
              target="_blank"
              >Google Chrome</a
            >
            or the new
            <a
              class="primary--text"
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              >Microsoft Edge</a
            >, or
            <a href="https://www.mozilla.org/" class="primary--text" target="_blank"
              >Firefox</a
            >.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-btn depressed class="primary-btn" type="submit"
                >Continue At Your Own Risk</v-btn
              >
            </v-col>
            <v-col>
              <v-checkbox
                v-model="suppress"
                label="Don't show this message again"
                dense
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BrowserWarning',
  data() {
    return {
      dialog: false,
      suppress: false,
    }
  },
  computed: {
    IE() {
      return /Trident\/|MSIE/.test(window.navigator.userAgent)
    },
  },
  created() {
    const ua = window.navigator.userAgent
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    // const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      ((p) => p.toString() === '[object SafariRemoteNotification]')(
        !window.safari ||
          (typeof safari !== 'undefined' && window.safari.pushNotification)
      )

    const isIE = /Trident\/|MSIE/.test(ua)
    const suppressed = localStorage.getItem('suppressBrowserWarningv2')

    if (!suppressed) {
      if (isIE || isSafari) {
        this.dialog = true
      }
    }
  },
  methods: {
    dismiss() {
      this.dialog = false
      if (this.suppress) localStorage.setItem('suppressBrowserWarningv2', true)
    },
  },
}
</script>
