<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M14.1667 10.8334H0.833333C0.61232 10.8334 0.400358 10.7456 0.244078 10.5893C0.0877974 10.433 0 10.221 0 10C0 9.77901 0.0877974 9.56705 0.244078 9.41076C0.400358 9.25448 0.61232 9.16669 0.833333 9.16669H14.1667C14.3877 9.16669 14.5996 9.25448 14.7559 9.41076C14.9122 9.56705 15 9.77901 15 10C15 10.221 14.9122 10.433 14.7559 10.5893C14.5996 10.7456 14.3877 10.8334 14.1667 10.8334Z"
    />
    <path
      d="M14.1667 10.8333C14.057 10.8339 13.9483 10.8129 13.8467 10.7714C13.7452 10.73 13.6529 10.6689 13.575 10.5916L10.2417 7.2583C10.164 7.18061 10.1023 7.08836 10.0603 6.98685C10.0182 6.88533 9.99658 6.77652 9.99658 6.66664C9.99658 6.55676 10.0182 6.44795 10.0603 6.34643C10.1023 6.24491 10.164 6.15267 10.2417 6.07497C10.3194 5.99727 10.4116 5.93564 10.5131 5.89359C10.6146 5.85154 10.7234 5.8299 10.8333 5.8299C10.9432 5.8299 11.052 5.85154 11.1535 5.89359C11.2551 5.93564 11.3473 5.99727 11.425 6.07497L14.7583 9.4083C14.8364 9.48577 14.8984 9.57794 14.9407 9.67949C14.983 9.78104 15.0048 9.88996 15.0048 9.99997C15.0048 10.11 14.983 10.2189 14.9407 10.3205C14.8984 10.422 14.8364 10.5142 14.7583 10.5916C14.6805 10.6689 14.5881 10.73 14.4866 10.7714C14.385 10.8129 14.2763 10.8339 14.1667 10.8333Z"
    />
    <path
      d="M10.8333 14.1667C10.7236 14.1673 10.6149 14.1463 10.5134 14.1048C10.4118 14.0634 10.3195 14.0022 10.2416 13.925C10.1635 13.8475 10.1015 13.7554 10.0592 13.6538C10.0169 13.5523 9.99512 13.4434 9.99512 13.3333C9.99512 13.2233 10.0169 13.1144 10.0592 13.0129C10.1015 12.9113 10.1635 12.8191 10.2416 12.7417L13.5749 9.40835C13.6526 9.33065 13.7449 9.26901 13.8464 9.22696C13.9479 9.18491 14.0567 9.16327 14.1666 9.16327C14.2765 9.16327 14.3853 9.18491 14.4868 9.22696C14.5883 9.26901 14.6806 9.33065 14.7583 9.40835C14.836 9.48604 14.8976 9.57829 14.9397 9.6798C14.9817 9.78132 15.0034 9.89013 15.0034 10C15.0034 10.1099 14.9817 10.2187 14.9397 10.3202C14.8976 10.4217 14.836 10.514 14.7583 10.5917L11.4249 13.925C11.3471 14.0022 11.2547 14.0634 11.1532 14.1048C11.0517 14.1463 10.943 14.1673 10.8333 14.1667Z"
    />
    <path
      d="M15.8333 20H4.16659C3.94557 20 3.73361 19.9122 3.57733 19.7559C3.42105 19.5996 3.33325 19.3877 3.33325 19.1667C3.33325 18.9457 3.42105 18.7337 3.57733 18.5774C3.73361 18.4211 3.94557 18.3333 4.16659 18.3333H15.8333C16.4963 18.3333 17.1322 18.0699 17.601 17.6011C18.0699 17.1323 18.3333 16.4964 18.3333 15.8333V4.16667C18.3333 3.50363 18.0699 2.86774 17.601 2.3989C17.1322 1.93006 16.4963 1.66667 15.8333 1.66667H4.16659C3.94557 1.66667 3.73361 1.57887 3.57733 1.42259C3.42105 1.26631 3.33325 1.05435 3.33325 0.833333C3.33325 0.61232 3.42105 0.400358 3.57733 0.244078C3.73361 0.0877974 3.94557 0 4.16659 0L15.8333 0C16.3804 0 16.9222 0.107774 17.4278 0.317169C17.9333 0.526563 18.3926 0.833478 18.7795 1.22039C19.1664 1.6073 19.4734 2.06663 19.6828 2.57215C19.8921 3.07768 19.9999 3.61949 19.9999 4.16667V15.8333C19.9999 16.3805 19.8921 16.9223 19.6828 17.4278C19.4734 17.9334 19.1664 18.3927 18.7795 18.7796C18.3926 19.1665 17.9333 19.4734 17.4278 19.6828C16.9222 19.8922 16.3804 20 15.8333 20Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusSubmit',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
