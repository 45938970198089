<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M20.0382 0H1.96178C0.880043 0 0 0.872383 0 1.94468V14.8782C0 15.9505 0.880043 16.8229 1.96178 16.8229H7.09878L6.43504 18.7234H5.78445C5.4285 18.7234 5.13992 19.0092 5.13992 19.3617C5.13992 19.7142 5.4285 20 5.78445 20H16.2155C16.5715 20 16.86 19.7142 16.86 19.3617C16.86 19.0092 16.5715 18.7234 16.2155 18.7234H15.565L14.9012 16.8229H20.0382C21.12 16.8229 22 15.9506 22 14.8782V1.94468C22 0.872383 21.12 0 20.0382 0ZM1.96178 1.2766H20.0382C20.4092 1.2766 20.7109 1.5763 20.7109 1.94468V3.82038H1.28906V1.94468C1.28906 1.5763 1.59083 1.2766 1.96178 1.2766ZM14.201 18.7234H7.79904L8.46278 16.8229H13.5372L14.201 18.7234ZM20.0382 15.5464H1.96178C1.59083 15.5464 1.28906 15.2467 1.28906 14.8783V5.09698H20.7109V14.8783C20.7109 15.2466 20.4092 15.5464 20.0382 15.5464Z"
    />
    <path
      d="M8.24763 7.52024C7.98733 7.27981 7.57947 7.29394 7.3367 7.55168L5.13803 9.88636C4.90712 10.1316 4.90712 10.5119 5.13803 10.757L7.3367 13.0917C7.46367 13.2265 7.63567 13.2947 7.80819 13.2947C7.96559 13.2947 8.12346 13.2379 8.24763 13.1232C8.50798 12.8828 8.52221 12.4789 8.27939 12.2211L6.49073 10.3217L8.27939 8.42241C8.52216 8.16458 8.50794 7.76066 8.24763 7.52024Z"
    />
    <path
      d="M11.95 7.38036C11.6118 7.2707 11.2478 7.45334 11.1369 7.78836L9.59321 12.4577C9.48244 12.7927 9.6669 13.1533 10.0052 13.263C10.0718 13.2845 10.1394 13.2948 10.2059 13.2948C10.477 13.2948 10.7293 13.124 10.8182 12.855L12.362 8.18563C12.4728 7.85057 12.2883 7.49006 11.95 7.38036Z"
    />
    <path
      d="M14.6633 7.55165C14.4204 7.29387 14.0126 7.27974 13.7523 7.52021C13.492 7.76063 13.4777 8.16455 13.7206 8.42233L15.5092 10.3217L13.7206 12.221C13.4778 12.4788 13.492 12.8827 13.7523 13.1231C13.8765 13.2379 14.0343 13.2946 14.1918 13.2946C14.3643 13.2946 14.5363 13.2265 14.6633 13.0917L16.8619 10.757C17.0928 10.5118 17.0928 10.1315 16.8619 9.88629L14.6633 7.55165Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusPreview',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
