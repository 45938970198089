<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1719_28092)">
      <path
        d="M15.0002 19.1667H3.3335C1.95516 19.1667 0.833496 18.045 0.833496 16.6667V5C0.833496 3.62167 1.95516 2.5 3.3335 2.5H10.0002C10.4602 2.5 10.8335 2.8725 10.8335 3.33333C10.8335 3.79417 10.4602 4.16667 10.0002 4.16667H3.3335C2.87433 4.16667 2.50016 4.54 2.50016 5V16.6667C2.50016 17.1267 2.87433 17.5 3.3335 17.5H15.0002C15.4593 17.5 15.8335 17.1267 15.8335 16.6667V10C15.8335 9.53917 16.2068 9.16667 16.6668 9.16667C17.1268 9.16667 17.5002 9.53917 17.5002 10V16.6667C17.5002 18.045 16.3785 19.1667 15.0002 19.1667Z"
        fill="#3D4A65"
      />
      <path
        d="M18.3332 0.833374H13.3332C12.9965 0.833374 12.6924 1.03587 12.5632 1.34754C12.434 1.65921 12.5057 2.01754 12.744 2.25587L14.6549 4.16671L8.57738 10.2442C8.25155 10.57 8.25155 11.0967 8.57738 11.4225C8.73988 11.585 8.95322 11.6667 9.16655 11.6667C9.37988 11.6667 9.59322 11.585 9.75572 11.4225L15.8332 5.34504L17.744 7.25587C17.9032 7.41504 18.1165 7.50004 18.3332 7.50004C18.4407 7.50004 18.549 7.48004 18.6524 7.43671C18.9632 7.30837 19.1665 7.00337 19.1665 6.66671V1.66671C19.1665 1.20587 18.7932 0.833374 18.3332 0.833374Z"
        fill="#3D4A65"
      />
    </g>
    <defs>
      <clipPath id="clip0_1719_28092">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: 'cusOpenExternal' }
</script>

<style></style>
