<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled"
    :class="[
      'btn',
      $vuetify.theme.dark && hideGradient ? 'dark' : '',
      hideGradient ? 'outlined-btn' : 'gradient',
      disabled ? 'disabled' : '',
    ]"
    ><slot
  /></v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'PrimaryButton',
  props: {
    hideGradient: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  color: black !important;
}
.dark {
  color: white !important;
}
.gradient {
  background: linear-gradient(180deg, #ffde4b 0%, #ffc844 100%);
  box-shadow: 0px 8px 16px 0px #ffc84426;
}

.outlined-btn {
  border: 1px solid #d8dbe0;
  box-shadow: 0px 12px 16px -16px #0f0f0f29;
}

.disabled {
  background: #d8dbe0;
  box-shadow: unset;
  color: #ffffff;
}
</style>
