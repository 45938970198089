<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M17.5 17.8333H2.49996C2.27895 17.8333 2.06698 17.9211 1.9107 18.0774C1.75442 18.2337 1.66663 18.4456 1.66663 18.6666C1.66663 18.8877 1.75442 19.0996 1.9107 19.2559C2.06698 19.4122 2.27895 19.5 2.49996 19.5H17.5C17.721 19.5 17.9329 19.4122 18.0892 19.2559C18.2455 19.0996 18.3333 18.8877 18.3333 18.6666C18.3333 18.4456 18.2455 18.2337 18.0892 18.0774C17.9329 17.9211 17.721 17.8333 17.5 17.8333Z"
      fill="#3D4A65"
    />
    <path
      d="M9.99999 1.66669C9.77898 1.66669 9.56702 1.75448 9.41074 1.91076C9.25446 2.06705 9.16666 2.27901 9.16666 2.50002V12.325L6.42499 9.57502C6.26807 9.4181 6.05524 9.32995 5.83333 9.32995C5.61141 9.32995 5.39858 9.4181 5.24166 9.57502C5.08474 9.73194 4.99658 9.94477 4.99658 10.1667C4.99658 10.3886 5.08474 10.6014 5.24166 10.7584L9.40833 14.925C9.48579 15.0031 9.57796 15.0651 9.67951 15.1074C9.78106 15.1497 9.88998 15.1715 9.99999 15.1715C10.11 15.1715 10.2189 15.1497 10.3205 15.1074C10.422 15.0651 10.5142 15.0031 10.5917 14.925L14.7583 10.7584C14.836 10.6807 14.8977 10.5884 14.9397 10.4869C14.9818 10.3854 15.0034 10.2766 15.0034 10.1667C15.0034 10.0568 14.9818 9.948 14.9397 9.84648C14.8977 9.74496 14.836 9.65272 14.7583 9.57502C14.6806 9.49732 14.5884 9.43569 14.4869 9.39364C14.3853 9.35159 14.2765 9.32995 14.1667 9.32995C14.0568 9.32995 13.948 9.35159 13.8465 9.39364C13.7449 9.43569 13.6527 9.49732 13.575 9.57502L10.8333 12.325V2.50002C10.8333 2.27901 10.7455 2.06705 10.5892 1.91076C10.433 1.75448 10.221 1.66669 9.99999 1.66669Z"
      fill="#3D4A65"
    />
  </svg>
</template>

<script>
export default { name: 'cusDownload' }
</script>

<style scoped lang="scss">
.svg {
  fill: currentColor;
}
</style>
