<template>
  <svg
    width="36"
    height="42"
    viewBox="0 0 36 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.4317 35.3225H10.3773C8.71874 35.3225 7.37915 33.9827 7.37915 32.324V11.4626C7.37915 5.65714 12.0996 1 17.8407 1C23.5818 1 28.3022 5.72093 28.3022 11.4626V32.3878C28.366 33.9827 27.0264 35.3225 25.4317 35.3225ZM13.3754 29.3894H22.4336V11.4626C22.4336 8.97455 20.3923 6.93306 17.9045 6.93306C15.4167 6.93306 13.3754 8.97455 13.3754 11.4626V29.3894Z"
      fill="#D8DBE0"
      stroke="#3D4A65"
      stroke-width="1.35844"
      stroke-miterlimit="10"
    />
    <path
      d="M31.6191 41.0004H4.38086C2.53096 41.0004 1 39.4693 1 37.6192V19.5648C1 17.7147 2.53096 16.1836 4.38086 16.1836H31.6191C33.469 16.1836 35 17.7147 35 19.5648V37.6192C35 39.4693 33.469 41.0004 31.6191 41.0004Z"
      fill="#D8DBE0"
      stroke="#3D4A65"
      stroke-width="1.35844"
      stroke-miterlimit="10"
    />
    <path
      d="M19.9787 29.1588C20.8987 28.5216 21.5225 27.452 21.5225 26.2227C21.5225 24.2504 19.9408 22.6684 17.9685 22.6684C15.9962 22.6684 14.4145 24.2504 14.4145 26.2227C14.4145 27.452 15.0384 28.5216 15.9584 29.1588V31.8664C15.9584 32.9869 16.848 33.8767 17.9685 33.8767C19.089 33.8767 19.9787 32.9869 19.9787 31.8664V29.1588Z"
      fill="white"
      stroke="#3D4A65"
      stroke-width="1.35844"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusLock',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
