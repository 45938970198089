<template>
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3678 17.3913L19.6841 20L20.5787 24.3479L17.0003 21.7392L13.422 24.3479L14.3166 20L11.6328 17.3913H15.2111L17.0003 13.9131L18.7895 17.3913H22.3678Z"
      fill="#FFDC56"
    />
    <path
      d="M29.5252 0L33.9981 4.34783C29.5252 17.3913 33.9981 14.7826 33.9981 24.3478C33.9981 33.9565 17.001 40 17.001 40C17.001 40 0.00390625 33.9565 0.00390625 24.3478C0.00390625 14.7826 4.47683 17.3913 0.00390625 4.34783L4.47683 0C6.266 1.73913 7.16058 3.47826 10.7389 3.47826C12.5281 3.47826 17.001 1.92174 17.001 0C17.001 1.92174 21.4739 3.47826 23.2631 3.47826C26.8414 3.47826 27.736 1.73913 29.5252 0ZM28.6127 28.0522C32.191 23.8261 29.5073 17.0435 28.6127 13.6609C27.7181 10.2783 29.5073 5.21739 29.5073 5.21739C27.7181 6.90435 26.8235 7.74783 23.2452 7.74783C21.5187 7.74783 17.2694 7.13043 17.001 5.3913C17.0084 5.33363 17.0114 5.2755 17.01 5.21739H16.9921C16.9906 5.2755 16.9936 5.33363 17.001 5.3913C16.7326 7.13043 12.4834 7.74783 10.7568 7.74783C7.17847 7.74783 6.28389 6.90435 4.49472 5.21739C4.49472 5.21739 6.28389 10.287 5.3893 13.6609C4.49472 17.0348 1.81097 23.8261 5.3893 28.0522C8.86924 32.1478 16.5537 35.4609 16.9921 35.6522H17.01C17.4483 35.4609 25.1328 32.1478 28.6127 28.0522Z"
      fill="#FFDB4A"
    />
    <path
      d="M28.6119 13.6613C29.5065 17.0439 32.1903 23.8265 28.6119 28.0526C25.132 32.1482 17.4475 35.4613 17.0092 35.6526H16.9913C16.5529 35.4613 8.86844 32.1482 5.38851 28.0526C1.81017 23.8265 4.49393 17.0439 5.38851 13.6613C6.2831 10.2786 4.49393 5.21777 4.49393 5.21777C6.2831 6.90473 7.17768 7.74821 10.756 7.74821C12.4826 7.74821 16.7318 7.13082 17.0002 5.39169C17.2686 7.13082 21.5179 7.74821 23.2444 7.74821C26.8228 7.74821 27.7173 6.90473 29.5065 5.21777C29.5065 5.21777 27.7173 10.2873 28.6119 13.6613ZM19.684 20.0004L22.3677 17.3917H18.7894L17.0002 13.9134L15.211 17.3917H11.6327L14.3165 20.0004L13.4219 24.3482L17.0002 21.7395L20.5786 24.3482L19.684 20.0004Z"
      fill="#5E98EB"
    />
    <path
      d="M30.3079 14.7241C30.1585 14.2162 30.0217 13.7493 29.9116 13.3328C29.1298 10.3832 30.7597 5.68059 30.7758 5.63625C30.8727 5.35919 30.8709 5.05851 30.7708 4.78254C30.6707 4.50658 30.478 4.27136 30.2237 4.11471C29.9694 3.95806 29.6683 3.88907 29.3688 3.91885C29.0693 3.94862 28.7888 4.07542 28.5724 4.27886C26.8575 5.89799 26.2796 6.4432 23.2443 6.4432C21.1259 6.4432 18.6121 5.71016 18.3241 5.17799C18.274 4.87205 18.1141 4.59322 17.8728 4.39091C17.6314 4.18861 17.3242 4.07591 17.0055 4.07277C16.686 4.06969 16.3761 4.17884 16.1329 4.38015C15.8896 4.58146 15.7293 4.86138 15.6815 5.16842C15.4176 5.70233 12.8868 6.4432 10.7613 6.4432C7.72594 6.4432 7.14804 5.89799 5.43312 4.28146C5.21737 4.0744 4.93584 3.94446 4.6344 3.91281C4.33295 3.88117 4.02937 3.94969 3.77312 4.10721C3.51686 4.26474 3.32304 4.50197 3.22324 4.78026C3.12343 5.05855 3.12352 5.36149 3.2235 5.63973C3.68421 6.94407 4.73893 10.878 4.08677 13.3362C3.97764 13.7493 3.84076 14.2162 3.69137 14.7276C2.59729 18.4345 0.774129 24.6562 4.35336 28.8823C7.81988 32.9615 15.0186 36.2223 16.4428 36.8423C16.6213 36.9188 16.8144 36.9576 17.0095 36.9562C17.2045 36.9549 17.3971 36.9134 17.5744 36.8345C18.9816 36.2258 26.1803 32.9615 29.6513 28.8806C33.2261 24.6562 31.4029 18.4345 30.3079 14.7241ZM28.6118 28.0519C25.1319 32.1475 17.4474 35.4606 17.009 35.6519H16.9912C16.5528 35.4606 8.86833 32.1475 5.3884 28.0519C1.81006 23.8258 4.49381 17.0432 5.3884 13.6606C6.28298 10.278 4.49381 5.21712 4.49381 5.21712C6.28298 6.90407 7.17757 7.74755 10.7559 7.74755C12.4825 7.74755 16.7317 7.13016 17.0001 5.39103C17.2685 7.13016 21.5178 7.74755 23.2443 7.74755C26.8226 7.74755 27.7172 6.90407 29.5064 5.21712C29.5064 5.21712 27.7172 10.2867 28.6118 13.6606C29.5064 17.0345 32.1901 23.8258 28.6118 28.0519Z"
      fill="#FFA41F"
    />
    <path
      d="M28.6119 13.6613C27.7173 10.2873 29.5065 5.21777 29.5065 5.21777C27.7173 6.90473 26.8228 7.74821 23.2444 7.74821C21.5179 7.74821 17.2686 7.13082 17.0002 5.39169C16.7318 7.13082 12.4826 7.74821 10.756 7.74821C7.17768 7.74821 6.2831 6.90473 4.49393 5.21777C4.49393 5.21777 6.2831 10.2873 5.38851 13.6613C4.49393 17.0352 1.81017 23.8265 5.38851 28.0526C8.86844 32.1482 16.5529 35.4613 16.9913 35.6526H17.0092C17.4475 35.4613 25.132 32.1482 28.6119 28.0526C32.1903 23.8265 29.5065 17.0439 28.6119 13.6613ZM27.5778 27.2265C24.7992 30.4908 18.9164 33.3465 17.0002 34.2213C15.1091 33.3578 9.20749 30.4978 6.42444 27.2239C3.74069 24.056 5.26148 18.8708 6.27325 15.4404C6.42981 14.9091 6.57294 14.4204 6.68745 13.9865C7.07032 12.1515 7.07032 10.2605 6.68745 8.4256C7.98617 8.90064 9.37072 9.11395 10.7569 9.05256C10.8097 9.05256 14.8291 8.99864 17.0011 7.5056C19.1732 8.99864 23.1925 9.05256 23.2453 9.05256C24.6315 9.11395 26.0161 8.90064 27.3148 8.4256C26.9324 10.2603 26.9324 12.1509 27.3148 13.9856C27.4302 14.4204 27.5733 14.9091 27.7299 15.4404C28.7345 18.8699 30.258 24.056 27.5778 27.2221V27.2265Z"
      fill="white"
    />
    <path
      d="M22.7163 17.7499C22.8623 17.608 22.9077 17.3919 22.8311 17.2033C22.7546 17.0147 22.5713 16.8913 22.3678 16.8913H19.0946L17.4449 13.6844C17.3593 13.5178 17.1876 13.4131 17.0003 13.4131C16.813 13.4131 16.6414 13.5178 16.5557 13.6844L14.9061 16.8913H11.6328C11.4293 16.8913 11.2461 17.0147 11.1695 17.2033C11.093 17.3919 11.1384 17.608 11.2843 17.7499L13.7716 20.1676L12.9322 24.2471C12.8906 24.4494 12.9777 24.6565 13.1514 24.7683C13.3251 24.8801 13.5496 24.8736 13.7165 24.7519L17.0003 22.3579L20.2841 24.7519C20.451 24.8736 20.6756 24.8801 20.8493 24.7683C21.023 24.6565 21.11 24.4494 21.0684 24.2471L20.229 20.1676L22.7163 17.7499Z"
      fill="url(#paint0_linear_1452_31926)"
      stroke="#DEAC1E"
      stroke-linejoin="round"
    />
    <path
      d="M23.1943 17.058C23.1265 16.8992 23.0118 16.7634 22.8647 16.6679C22.7176 16.5724 22.5446 16.5215 22.3677 16.5215H19.3422L17.8053 13.5241C17.7226 13.3893 17.6054 13.2778 17.4651 13.2003C17.3247 13.1228 17.1661 13.082 17.0047 13.082C16.8432 13.082 16.6846 13.1228 16.5442 13.2003C16.4039 13.2778 16.2867 13.3893 16.204 13.5241L14.6582 16.5215H11.6327C11.4558 16.5215 11.2828 16.5726 11.1358 16.6681C10.9887 16.7637 10.8741 16.8995 10.8064 17.0583C10.7387 17.2172 10.721 17.392 10.7555 17.5607C10.79 17.7293 10.8751 17.8842 11.0002 18.0059L13.3449 20.2859L12.5452 24.1737C12.5088 24.3465 12.5274 24.526 12.5985 24.6884C12.6696 24.8507 12.7898 24.9882 12.9432 25.0825C13.0966 25.1769 13.2758 25.2236 13.4572 25.2166C13.6386 25.2096 13.8134 25.1491 13.9586 25.0432L17.0002 22.8258L20.0418 25.0432C20.1869 25.1491 20.3618 25.2096 20.5432 25.2166C20.7245 25.2236 20.9038 25.1769 21.0572 25.0825C21.2105 24.9882 21.3307 24.8507 21.4018 24.6884C21.4729 24.526 21.4915 24.3465 21.4552 24.1737L20.6555 20.2824L23.0002 18.0024C23.1246 17.8811 23.2095 17.7267 23.244 17.5587C23.2785 17.3907 23.2612 17.2165 23.1943 17.058ZM19.6839 19.9998L20.5785 24.3476L17.0002 21.7389L13.4218 24.3476L14.3164 19.9998L11.6327 17.3911H15.211L17.0002 13.9128L18.7893 17.3911H22.3677L19.6839 19.9998Z"
      fill="white"
    />
    <path
      d="M31.7052 21.333C32.0591 24.0135 31.3207 26.7223 29.6476 28.8808C26.1793 32.9617 18.9806 36.2225 17.5707 36.8347C17.3934 36.9136 17.2008 36.9551 17.0058 36.9565C16.8107 36.9578 16.6176 36.919 16.4391 36.8425C15.0149 36.2225 7.8162 32.9617 4.34968 28.8825C2.6765 26.7238 1.93808 24.0147 2.29214 21.3338C1.64897 20.6612 1.08323 19.9223 0.604953 19.1304C0.175288 20.8373 -0.0270521 22.5907 0.00289741 24.3478C0.00289741 33.9565 17 39.9999 17 39.9999C17 39.9999 33.9971 33.9565 33.9971 24.3478C34.0271 22.5907 33.8247 20.8373 33.395 19.1304C32.9161 19.9223 32.3495 20.6609 31.7052 21.333Z"
      fill="#FFCA46"
    />
    <path
      d="M30.4188 22.5219C30.5038 24.5145 29.864 26.4726 28.6117 28.0524C25.1318 32.148 17.4473 35.461 17.009 35.6524H16.9911C16.5527 35.461 8.86824 32.148 5.38831 28.0524C4.13598 26.4726 3.49618 24.5145 3.58124 22.5219C3.12911 22.1515 2.69986 21.7554 2.29573 21.3358C1.94167 24.0167 2.68009 26.7258 4.35327 28.8845C7.81978 32.9637 15.0185 36.2245 16.4427 36.8445C16.6212 36.921 16.8143 36.9598 17.0094 36.9584C17.2044 36.9571 17.397 36.9156 17.5743 36.8367C18.9815 36.228 26.1802 32.9637 29.6512 28.8828C31.3243 26.7243 32.0627 24.0155 31.7088 21.335C31.3034 21.7552 30.8727 22.1516 30.4188 22.5219Z"
      fill="#FFA233"
    />
    <path
      d="M4.97852 23.5532C5.07013 24.8901 5.5739 26.1691 6.42416 27.2237C9.20722 30.4976 15.1088 33.3576 16.9999 34.221C18.9161 33.3463 24.7989 30.4906 27.5775 27.2219C28.4271 26.1678 28.9305 24.8894 29.0223 23.5532C25.4531 25.8265 21.2667 27.0116 16.9999 26.9567C12.7334 27.0118 8.5473 25.8266 4.97852 23.5532Z"
      fill="#457EFF"
    />
    <path
      d="M5.38781 28.0524C8.86775 32.1481 16.5522 35.4611 16.9906 35.6524H17.0085C17.4468 35.4611 25.1313 32.1481 28.6112 28.0524C29.8636 26.4727 30.5033 24.5146 30.4183 22.522C29.9716 22.8901 29.5052 23.2349 29.0209 23.555C28.9292 24.8912 28.4258 26.1696 27.5762 27.2237C24.7985 30.4907 18.9157 33.3463 16.9995 34.2211C15.1084 33.3576 9.20679 30.4976 6.42374 27.2237C5.57347 26.1692 5.06971 24.8901 4.97809 23.5533C4.49356 23.2342 4.0271 22.8899 3.58075 22.522C3.49569 24.5146 4.13548 26.4727 5.38781 28.0524Z"
      fill="#D6E0FB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1452_31926"
        x1="17.0003"
        y1="13.9131"
        x2="17.0003"
        y2="24.3479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFDE4B" />
        <stop offset="1" stop-color="#FFC844" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default { name: 'cusStarShield' }
</script>

<style></style>
