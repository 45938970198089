<template>
  <svg
    width="20"
    height="24"
    class="svg"
    viewBox="0 0 20 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.06703 3.5C2.37711 3.5 2.58383 3.3 2.58383 3V1.15C2.58383 1.05 2.63551 1 2.73887 1H4.65101C4.96109 1 5.16781 0.8 5.16781 0.5C5.16781 0.2 4.96109 0 4.65101 0H2.73887C2.06703 0 1.55024 0.5 1.55024 1.15V3C1.55024 3.3 1.75695 3.5 2.06703 3.5Z"
    />
    <path
      d="M2.73886 14.45H4.65101C4.96108 14.45 5.1678 14.25 5.1678 13.95C5.1678 13.65 4.96108 13.45 4.65101 13.45H2.73886C2.68718 13.45 2.58382 13.4 2.58382 13.3V11.45C2.58382 11.15 2.37711 10.95 2.06703 10.95C1.75695 10.95 1.55023 11.15 1.55023 11.45V13.3C1.55023 13.9 2.06703 14.45 2.73886 14.45Z"
    />
    <path
      d="M17.9845 10.95C17.6745 10.95 17.4677 11.15 17.4677 11.45V13.3C17.4677 13.4 17.4161 13.45 17.3127 13.45H15.4006C15.0905 13.45 14.8838 13.65 14.8838 13.95C14.8838 14.25 15.0905 14.45 15.4006 14.45H17.3127C17.9845 14.45 18.5013 13.95 18.5013 13.3V11.45C18.5013 11.15 18.2946 10.95 17.9845 10.95Z"
    />
    <path
      d="M15.4006 1H17.3127C17.4161 1 17.4677 1.05 17.4677 1.15V3C17.4677 3.3 17.6745 3.5 17.9845 3.5C18.2946 3.5 18.5013 3.3 18.5013 3V1.15C18.5013 0.5 17.9845 0 17.3127 0H15.4006C15.0905 0 14.8838 0.2 14.8838 0.5C14.8838 0.8 15.0905 1 15.4006 1Z"
    />
    <path
      d="M0.516796 24H19.4832C19.7933 24 20 23.8 20 23.5V21.6C20 19.45 18.2429 17.5 15.4522 16.6C13.9018 16.1 12.8682 15.1 12.8682 14V13.2C14.4703 11.75 15.5039 9.25001 15.5039 7.00001C15.5039 3.70001 13.385 1.70001 9.97416 1.70001C6.56331 1.70001 4.49612 3.70001 4.49612 7.00001C4.49612 9.25001 5.52972 11.75 7.13178 13.2V14C7.13178 15.1 6.14987 16.1 4.5478 16.6C1.80879 17.5 0 19.45 0 21.6V23.5C0 23.8 0.258398 24 0.516796 24V24ZM5.52972 7.00001C5.52972 4.30001 7.23514 2.70001 10.0258 2.70001C12.8682 2.70001 14.522 4.30001 14.522 7.00001C14.522 9.95001 12.5065 13.45 10.0258 13.45C7.54522 13.45 5.52972 9.90001 5.52972 7.00001V7.00001ZM1.03359 21.6C1.03359 19.9 2.5323 18.3 4.85788 17.55C6.87338 16.9 8.11369 15.55 8.11369 14V13.95C8.68217 14.25 9.30233 14.45 9.97416 14.45C10.646 14.45 11.2661 14.25 11.8346 13.95V14C11.8346 15.55 13.0749 16.9 15.0904 17.55C17.416 18.3 18.9147 19.9 18.9147 21.6V23H1.03359V21.6Z"
    />
  </svg>
</template>

<script>
export default { name: 'face' }
</script>

<style>
.svg {
  fill: currentColor;
}
</style>
