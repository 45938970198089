<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C9.62663 0 7.30655 0.703787 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519945 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9962 8.81855 22.7308 5.76849 20.4811 3.51886C18.2315 1.26924 15.1814 0.00375324 12 0ZM12 21.8182C10.0582 21.8182 8.15991 21.2424 6.54531 20.1635C4.93072 19.0847 3.6723 17.5513 2.92919 15.7573C2.18607 13.9632 1.99164 11.9891 2.37048 10.0846C2.74931 8.18003 3.6844 6.43059 5.0575 5.0575C6.4306 3.6844 8.18003 2.74931 10.0846 2.37047C11.9891 1.99163 13.9632 2.18607 15.7573 2.92918C17.5513 3.6723 19.0847 4.93072 20.1635 6.54531C21.2424 8.1599 21.8182 10.0581 21.8182 12C21.8153 14.6031 20.78 17.0987 18.9393 18.9393C17.0987 20.78 14.6031 21.8153 12 21.8182Z"
      fill="url(#paint0_linear_1566_29253)"
    />
    <path
      d="M7.94795 16.9946C7.70278 17.009 7.46166 16.9265 7.27544 16.7646C6.90819 16.3909 6.90819 15.7872 7.27544 15.4135L15.3172 7.2781C15.6992 6.91651 16.2986 6.93661 16.656 7.32304C16.9792 7.67248 16.998 8.20959 16.7001 8.58131L8.61097 16.7646C8.42716 16.9242 8.18991 17.0065 7.94795 16.9946Z"
      fill="url(#paint1_linear_1566_29253)"
    />
    <path
      d="M15.9804 16.9948C15.732 16.9938 15.4938 16.894 15.3174 16.7169L7.27561 8.58153C6.93537 8.17957 6.98162 7.57466 7.37895 7.23042C7.73358 6.92319 8.25658 6.92319 8.61117 7.23042L16.7003 15.3658C17.0822 15.7275 17.1019 16.3339 16.7444 16.7202C16.7302 16.7356 16.7155 16.7505 16.7003 16.7649C16.5022 16.9391 16.2415 17.0224 15.9804 16.9948Z"
      fill="url(#paint2_linear_1566_29253)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1566_29253"
        x1="19.2275"
        y1="1.26195"
        x2="-11.536"
        y2="36.9488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6050" />
        <stop offset="1" stop-color="#CF3429" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1566_29253"
        x1="14.9406"
        y1="7.54412"
        x2="2.14034"
        y2="22.2961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6050" />
        <stop offset="1" stop-color="#CF3429" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1566_29253"
        x1="15.0211"
        y1="7.52581"
        x2="2.19408"
        y2="22.3346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6050" />
        <stop offset="1" stop-color="#CF3429" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'cusRedX',
}
</script>

<style></style>
