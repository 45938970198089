<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 6.10352e-05C8.44771 6.10352e-05 8 0.447777 8 1.00006V8.00006H1C0.447715 8.00006 0 8.44778 0 9.00006C0 9.55235 0.447716 10.0001 1 10.0001H8V17.0001C8 17.5523 8.44771 18.0001 9 18.0001C9.55229 18.0001 10 17.5523 10 17.0001V10.0001H17C17.5523 10.0001 18 9.55235 18 9.00006C18 8.44778 17.5523 8.00006 17 8.00006H10V1.00006C10 0.447776 9.55229 6.10352e-05 9 6.10352e-05Z"
      fill="#3D4A65"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusAddProject',
}
</script>

<style></style>
