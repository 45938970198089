<template>
  <svg
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.42844 27.789L0.28263 19.8812C0.102692 19.7256 0 19.504 0 19.2715H9.42849V27.789H9.42844Z"
      fill="#2FBEEE"
    />
    <path
      d="M10.5703 27.789L19.7162 19.8812C19.8961 19.7256 19.9988 19.504 19.9988 19.2715H10.5703V27.789Z"
      fill="#4893FF"
    />
    <path
      d="M9.42844 0.210938L0.28263 8.1187C0.102692 8.27428 0 8.49588 0 8.72841H9.42849V0.210938H9.42844Z"
      fill="#2FBEEE"
    />
    <path
      d="M10.5703 0.210938L19.7162 8.11875C19.8961 8.27434 19.9988 8.49593 19.9988 8.72846H10.5703V0.210938Z"
      fill="#4893FF"
    />
    <path
      d="M19.9994 8.72112C19.9964 8.38518 19.7022 8.11836 19.3525 8.11836H14.3103L10.7799 0.487156C10.6429 0.190914 10.3371 0 9.99968 0C9.66223 0 9.35643 0.190914 9.21942 0.487156L5.68902 8.11836H0.646889C0.297202 8.11836 0.00296007 8.38518 0 8.72112V19.2789C0.00296007 19.6148 0.297202 19.8816 0.646889 19.8816H5.68902L9.21942 27.5128C9.35643 27.8091 9.66223 28 9.99968 28C10.3371 28 10.6429 27.8091 10.7799 27.5128L14.3103 19.8816H19.3525C19.7022 19.8816 19.9964 19.6148 19.9994 19.2789C19.9994 19.2765 19.9994 8.72348 19.9994 8.72112Z"
      fill="#7ED6F4"
    />
    <path
      d="M10.7803 27.5128L14.3107 19.8816H19.3528C19.7025 19.8816 19.9967 19.6148 19.9997 19.2789C19.9997 19.2766 19.9997 8.72348 19.9997 8.72112C19.9967 8.38518 19.7025 8.11836 19.3528 8.11836H14.3107L10.7803 0.487156C10.6432 0.190914 10.3374 0 10 0V28C10.3374 28 10.6432 27.8091 10.7803 27.5128Z"
      fill="#2FBEEE"
    />
    <path d="M5.68945 8.11914H14.3108V19.8824H5.68945V8.11914Z" fill="#2FBEEE" />
    <path d="M10 8.11914H14.3107V19.8824H10V8.11914Z" fill="#4893FF" />
  </svg>
</template>

<script>
export default {
  name: 'cusExperience',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
