export const motivators = [
  'Unleash your inner coder and conquer the weekly challenge to level up your computational superpowers!',
  "Crack the code, climb the ranks! Complete this week's challenge and soar to new coding heights.",
  "Think you've got what it takes? Prove it by mastering our weekly quest!",
  "Don't just learn, level up! Embark on the challenge, and let the adventure begin.",
  'Unlock your coding potential: accept the challenge, embrace the journey!',
  "Coding champions are made, not born. Accept the challenge, and let's create greatness!",
  'Challenge accepted? Your coding odyssey awaits – set sail and conquer the virtual realms!',
  'In the world of coding, challenges are opportunities in disguise. Face them, solve them, own them!',
  'Elevate your code game! Engage with the challenge and script your way to success.',
  "Join the league of code conquerors – tackle this week's challenge and stand out among the virtual stars!",
  'Dive into the code ocean, embrace challenges like treasures, and emerge as a coding legend!',
  'Weekly challenge: where coding dreams meet reality. Dare to code, dare to excel!',
  'Coding muscles need exercise too! Flex your skills by embracing the challenge and emerging stronger.',
  'Crack codes, earn accolades! Step up to the challenge and script your own success story.',
]

export const getRandomMotivatorMessage = function () {
  return motivators[Math.floor(Math.random() * motivators.length)]
}

export const failures = [
  'Challenge faced! Await the next opportunity to test your coding mettle.',
  "You've ventured into the challenge – more coding quests are in store for you soon.",
  'Challenge embraced! Stay tuned for future coding puzzles to keep your skills sharp.',
  "You've taken on the challenge – gear up for upcoming coding adventures.",
  'Coding challenge engaged! Keep an eye out for the next quest on the horizon.',
  'Challenge accepted! The journey continues with more coding mysteries to explore.',
  "You've stepped into the challenge realm – stay curious for what's coming next.",
  'Coding quest initiated! Await the next challenge to push your coding boundaries.',
  'Challenge underway! Keep your coding passion alive as new challenges await.',
  "You're on the challenge path! Prepare for future coding challenges to keep growing.",
  'Challenge activated! The next coding opportunity will be knocking soon.',
  'Coding journey started! Stay patient as we prepare your next challenge.',
  'Challenge in motion – stay ready for the next coding puzzle in the pipeline.',
  'Coding challenge embraced! Keep your coding spark alive for the next adventure.',
  "You've embarked on the challenge – more coding challenges await your skills.",
]

export const getRandomFailureMessage = function () {
  return failures[Math.floor(Math.random() * failures.length)]
}
