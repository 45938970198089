<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
  >
    <path
      d="M20 9.28574V16.4286C20 18.3977 18.3976 20 16.4285 20H3.57142C1.60232 20 0 18.3977 0 16.4286V3.57147C0 1.60236 1.60232 4.46502e-05 3.57142 4.46502e-05H10.7143C11.1087 4.46502e-05 11.4285 0.319865 11.4285 0.714329C11.4285 1.10879 11.1087 1.42861 10.7143 1.42861H3.57142C2.38982 1.42861 1.42857 2.38986 1.42857 3.57147V16.4286C1.42857 17.6102 2.38982 18.5714 3.57142 18.5714H16.4285C17.6101 18.5714 18.5714 17.6102 18.5714 16.4286V9.28574C18.5714 8.89128 18.8912 8.57145 19.2857 8.57145C19.6801 8.57145 20 8.89128 20 9.28574ZM5.20927 10.2093L15.2093 0.20933C15.4884 -0.0697766 15.9403 -0.0697766 16.2192 0.20933L19.7907 3.78075C20.0698 4.05986 20.0698 4.51182 19.7907 4.79075L9.79069 14.7907C9.65676 14.9247 9.47516 15 9.28569 15H5.71427C5.31981 15 4.99999 14.6802 4.99999 14.2857V10.7143C4.99999 10.5248 5.07535 10.3432 5.20927 10.2093ZM14.5814 2.85718L17.1428 5.4186L18.2757 4.28575L15.7143 1.72433L14.5814 2.85718ZM6.42856 13.5714H8.98998L16.1328 6.4286L13.5714 3.86718L6.42856 11.01V13.5714Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cusRename',
}
</script>

<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
