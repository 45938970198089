<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="item"
    :menu-props="{ bottom: true, offsetY: true, rounded: 't-xl b-xl' }"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'SelectBox',
  props: { rounded: { type: Boolean, default: false } },
}
</script>

<style lang="scss" scoped>
.item {
  border-radius: 8px !important;
}
</style>
