<template>
  <v-menu
    v-model="areNotificationsShowing"
    max-width="438px"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs" @click="toggleNotificationsPane">
        <v-badge v-if="unread > 0" :content="unread" overlap background color="red">
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list class="notification-box">
        <div style="font-size: 110%" class="d-block px-4 pt-4 pb-4">
          <strong>Notifications</strong>
        </div>
        <v-divider class="my-0" />
        <template v-for="(notification, i) in showableNotifications">
          <!-- TEST -->
          <!-- <v-list-item
            :class="notification.readStatus === 1 ? '' : 'unread-notification'"
            class="d-block notification px-0"
          >
            <br />
            <NotificationTop :notification="notification" class="px-4" />
            <div class="d-flex ml-3">
              <div>
                <img
                  src="https://media.codewizardshq.com/achievements/badges/badge_star.png"
                  style="max-width: 54px"
                />
              </div>
              <div style="font-size: 14px" class="ml-2">
                <div style="font-weight: 600">Some achievement completed!</div>
                <div>
                  <span>Claim it and get a reward of:</span>
                  <div>
                    <span
                      style="
                        font-weight: 600;
                        display: inline-flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-right: 4px;
                      "
                    >
                      <div
                        style="
                          max-height: 20px;
                          min-height: 20px;
                          max-width: 20px;
                          min-width: 20px;
                          background-color: lightpink;
                          border-radius: 100%;
                          margin-right: 2px;
                          overflow: hidden;
                        "
                      >
                        <img
                          src="https://cdn2.codewizardshq.com/Avatar/Body/Base_Fish_Blue.png"
                          style="max-height: 20px"
                        />
                      </div>

                      <span>Blue Fish</span>
                    </span>
                    <span
                      style="
                        font-weight: 600;
                        display: inline-flex;
                        flex-wrap: wrap;
                        align-items: center;
                      "
                    >
                      <img
                        style="max-height: 20px; margin-left: 4px"
                        src="/cusExperience.svg"
                      />
                      <span style="margin-left: 4px; margin-right: 10px">7</span>
                      <img style="max-height: 20px" src="/cusGoldCoin.svg" />
                      <span style="margin-left: 4px">20</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <v-list-item-content class="survey-link-wrapper">
              <div class="d-block px-4">
                <v-btn small outlined @click="$router.push('/achievements')"
                  >Claim
                </v-btn>
                <br />
                <br />
              </div>
            </v-list-item-content>
          </v-list-item> -->
          <!-- TEST -->

          <NotificationEntry :notification="notification" :key="notification.id" />
          <v-divider v-if="i < showableNotifications.length - 1" class="my-0" />
        </template>
        <p
          v-if="!showableNotifications || !showableNotifications.length"
          style="font-size: 90%; height: 40px; margin: 10px"
        >
          You haven't received any notifications yet
        </p>
      </v-list>
      <v-divider />
      <v-card-actions v-if="showableNotifications && showableNotifications.length">
        <v-spacer />
        <v-btn
          color="#2962FF"
          text
          @click="clearAllNotifications"
          :loading="isLoading.clearAllBtn"
          >Clear All</v-btn
        >
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import NotificationEntry from './NotificationEntry.vue'
import { useNotificationsStore } from '@/stores/notificationsStore'
import { storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'
import { axiosKey } from '@/utils/injectionKeys'
import { mixpanel } from '@/utils/helperFunctions'

const emit = defineEmits<{ (e: 'begin-student-feedback'): void }>()

const store = useNotificationsStore()
const { notifications, unread } = storeToRefs(store)
const { fetchNotifications, getNotificationCount } = store

const isLoading = ref({ clearAllBtn: false })
const areNotificationsShowing = ref(false)

const $http = inject(axiosKey)

// mounted
getNotificationCount()

const showableNotifications = computed(() =>
  notifications.value.filter((n) => !n.dismissed)
)

// functions
async function clearAllNotifications() {
  isLoading.value.clearAllBtn = true
  await $http.post('/notifications/clear')
  await fetchNotifications()
  isLoading.value.clearAllBtn = false
}

function toggleNotificationsPane() {
  if (areNotificationsShowing.value) return
  console.log('tray')
  $http
    .post('/notifications/tray')
    .then((res) => {
      unread.value = 0
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      mixpanel('Open Notifications')
    })
}
</script>

<style lang="scss" scoped>
.notification-box {
  padding: 0;
  max-height: 635px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
