<template>
  <div class="wrapper">
    <div :title="formattedDate(notification.date).toString()" class="timestamp">
      {{ timeSince(notification.date) }}
    </div>

    <v-btn v-bind="attrs" @click="clearNotification(notification.id)"
      ><v-icon dense>mdi-close</v-icon></v-btn
    >
  </div>
</template>

<script setup lang="ts">
import { Notification, useNotificationsStore } from '@/stores/notificationsStore'
import { timeSince, formattedDate } from '@/utils/dayjs'

type Props = { notification: Notification }
const props = defineProps<Props>()
const { clearNotification } = useNotificationsStore()

const attrs = { title: 'Dismiss notification', dense: true, icon: true, small: true }
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .timestamp {
    font-size: 12px;
    color: var(--v-common-lighten2);
  }
}
</style>
